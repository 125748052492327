import React from 'react';
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../shared/Loader";
import useLang from "../../../../src_shared/hooks/useLang";
import Field from "../../../../src_shared/components/form/field/Field";

const ShopVisibilityForm = ({getFieldProps, onSave, isWaiting, values}) => {
    const {getLangText} = useLang();

    return (
        <div className="default-shadow-box bigger-y-padd">
            <SwitchField
                {...getFieldProps('releasing_active')}
                label={getLangText('automaticProductRelease')}
                subLabel={getLangText('productWillReleaseAfterSpecificTime')}
                id="releasing_active"
                className="switch-form inline-label big-label align-items-start light-version mb-3"
            />
            {!!+values.releasing_active &&
                <>
                    <div className="ml-[44px]">
                        <div className="form-label">
                            {getLangText('minutesWillProductRelease')}
                        </div>
                        <Field
                            {...getFieldProps('releasing_minutes')}
                            className="hidden-arrows mt-2"
                            style={{maxWidth: '170px',}}
                            showLabel={false}
                            addon={getLangText('minutesLabel')}
                            type="number"
                            extraProps={{
                                min: 0,
                            }}
                        />
                    </div>
                    <div className="ml-[44px] mb-4">
                        <div className="form-label">
                            {getLangText('minutesWillSentPushAboutProductsRelease')}
                        </div>
                        <Field
                            {...getFieldProps('notification_minutes')}
                            className="hidden-arrows mt-2"
                            showLabel={false}
                            style={{maxWidth: '170px',}}
                            addon={getLangText('minutesLabel')}
                            type="number"
                            extraProps={{
                                min: 0,
                            }}
                        />
                    </div>
                </>
            }
            <SwitchField
                {...getFieldProps('mobile_app_orders')}
                label={getLangText('orderHistoryVisible')}
                subLabel={getLangText('orderHistoryVisibleSubLabel')}
                id="mobile_app_orders"
                className="switch-form inline-label big-label align-items-start light-version mb-3"
            />
            <SwitchField
                {...getFieldProps('mobile_app_reserve_list')}
                label={getLangText('reserveListVisible')}
                subLabel={getLangText('reserveListVisibleSubLabel')}
                id="mobile_app_reserve_list"
                className="switch-form inline-label align-items-start big-label light-version mb-3"
            />
            <div className="flex items-center w-full">
                <SwitchField
                    {...getFieldProps('mobile_app')}
                    label={getLangText('shopVisibleInAppLabel')}
                    id="mobile_app"
                    className="switch-form inline-label  big-label light-version mb-0"
                />
                <button
                    type="button"
                    className={`button primary ml-auto small-size ${isWaiting ? 'loading-button' : ''}`}
                    onClick={onSave}
                >
                    {isWaiting && <Loader isLoading/>}
                    {getLangText('saveButton')}
                </button>
            </div>
        </div>
    );
};

export default ShopVisibilityForm;
