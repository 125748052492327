import React from 'react';
import {
    ONBOARDING_ACCOUNT_TYPE_VIEW,
} from "../CreateShopFirstStep";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";

const options = [
    {id: 1, label: 'checkStatusesInOnePlace', color: '#FFB8DE'},
    {id: 2, label: 'seeProductsOnReserveList', color: '#ceeeff'},
    {id: 3, label: 'buyComfortableDirectlyInApp', color: '#FFCEC4'},
]
const CustomerType = ({setInternalView}) => {
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);

    return (
        <div
            className={`bg-[#eff2f6] sm:py-0 sm:px-0 sm:min-h-auto min-h-full sm:h-[100vh] w-[100vw] flex sm:items-center sm:justify-center`}>
            <div
                className={`w-full sm:my-auto md:px-0 sm:rounded-[12px] sm:shadow-default bg-[#F9FAFB] sm:bg-[white] md:w-[975px] min-h-[450px] md:min-h-[600px] flex flex-col animate-tutorialSlideAndFadeIn`}>
                <div className="grow flex-col flex">
                    <div className="flex flex-col grow-1 animate-tutorialSlideAndFadeIn sm:p-8 p-6 sm:bg-transparent bg-[#fff]">
                        <button
                            type="button"
                            onClick={() => setInternalView(ONBOARDING_ACCOUNT_TYPE_VIEW)}
                            className="text-desc flex items-center font-bold text-sm mr-auto sm:mb-6 mb-8 transition-all hover:text-[#575d68]">
                            <div
                                className="flex items-center justify-center w-[32px] h-[32px] bg-[#F3F4F6] rounded-full mr-2">
                                <i className="icon-arrow-left text-xs"/>
                            </div>
                            {getLangText('backButton')}
                        </button>
                        <div>
                            <img
                                width={100}
                                src="/assets/images/selmo-logo.svg" alt=""/>
                            <div className="text-[28px] font-bold leading-[34px] mt-3">
                                {getLangText('downloadAppToMakePurchases')}
                            </div>
                            <div className="text-desc text-sm font-medium mt-6 sm:mt-3.5 sm:whitespace-pre-wrap"
                                 dangerouslySetInnerHTML={{__html: getLangText('downloadAppToMakePurchasesSubLabel')}}/>
                            <a
                                style={{
                                    fontSize: 16,
                                    borderRadius: 12,
                                    padding: 16,
                                }}
                                target="_blank"
                                rel="noreferrer"
                                className="button primary black text-center medium-size sm:mt-6 mt-8 sm:mb-0 mb-4 min-w-full sm:min-w-[330px]"
                                href={`https://onelink.to/selmo?utm_source=selmo&utm_medium=panel&utm_campaign=panel_onboarding`}
                            >
                                {getLangText('downloadSelmoApp')}
                            </a>
                        </div>
                    </div>
                    <div className="lg:w-auto w-[100vw] pb-8 sm:mt-0 mt-8 animate-tutorialSlideAndFadeInDelay1 opacity-0">
                        <div className="mx-8 mb-6">
                            <div className="text-sm font-medium text-[#5FA5FA] mb-1">
                                {getLangText('selmoApp')}
                            </div>
                            <div className="text-[28px] font-bold leading-[34px]">
                                {getLangText('pricingWhyWorthyTitle')}
                            </div>
                        </div>
                        <div
                            className="lg:grid flex lg:grid-cols-3 gap-3 lg:gap-3 lg:overflow-hidden overflow-x-auto lg:px-8 px-6">
                            {options.map((option) => (
                                <div
                                    key={option.id}
                                    className={`overflow-hidden p-6 rounded-[18px] flex flex-col sm:pb-0 pb-0 min-w-[300px] sm:min-w-[auto] ${option.id === 1 ? 'onboarding-selmo-app-gradient-1' : ''} ${option.id === 2 ? 'onboarding-selmo-app-gradient-2' : ''} ${option.id === 3 ? 'onboarding-selmo-app-gradient-3' : ''}`}
                                >
                                    <div
                                        className="whitespace-break-spaces font-bold text-[#101827] leading-[22px] text-lg tracking-[-0.22px]"
                                        dangerouslySetInnerHTML={{__html: getLangText(option.label)}}/>
                                    <div className="pt-1.5 -mx-6 mt-auto">
                                        <img
                                            className="object-cover w-full h-full"
                                            src={`/assets/images/onboarding/mockup-slide${option.id}.webp`}
                                            alt="facebook logo" width={315} height={430}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerType;
