export const services = {
	API_LOGIN: 'apiregister/login',
	API_FORGET_PASSWORD: 'apiregister/forgetPassword',
	API_LOGOUT: 'apiregister/logout',
	API_REGISTER: 'apiregister/new',
	API_REGISTER_EMPLOYEE: 'apiregister/employee',
	API_RESET_PASSWORD: 'apiregister/resetPassword',
	API_LOGIN_DEV: 'apiregister/loginDev',
	API_LOGIN_GOOGLE: 'apiregister/loginGoogle',
	API_LOGIN_FACEBOOK: 'apiregister/loginFacebook',
	API_REGISTER_SOCIAL_LINKS: 'apiregister/socialLinks',
	API_REGISTER_SOCIALS: 'apiregister/socials',
	API_REGISTER_WEBINAR_CONFIRMATION: 'apiregister/webinarConfirmation',
	API_REGISTER_INSTAGRAM_LOGIN: 'apiregister/instagramLogin',
	API_REGISTER_LOG: 'apiregister/log',

	API_TUTORIAL_SHOP: 'apitutorial/shop',
	API_TUTORIAL_FACEBOOK: 'apitutorial/facebook',
	API_TUTORIAL_SOCIALS: 'apitutorial/socials',
	API_TUTORIAL_INSTAGRAM: 'apitutorial/instagram',
	API_TUTORIAL_FINISHED: 'apitutorial/finished',
	API_TUTORIAL_SHIPPING: 'apitutorial/shipping',
	API_TUTORIAL_PAYMENT: 'apitutorial/payment',
	API_TUTORIAL_INFO: 'apitutorial/info',
	API_CONNECT_FACEBOOK: 'apitutorial/connectFacebook',
	API_ONBOARDING_VIDEOS: 'apitutorial/onboardingVideos',
	API_TUTORIAL_STATUS: 'apitutorial/status',
	API_TUTORIAL_START_STATUS: 'apitutorial/startStatus',
	API_TUTORIAL_SEARCH_COUNTRIES: 'apitutorial/searchCountries',
	API_TUTORIAL_USER_ORIGIN: 'apitutorial/userOrigin',
	API_TUTORIAL_CREATE_INSTAGRAM_USER: 'apitutorial/createInstagramUser',
	API_TUTORIAL_SOCIAL_LINKS: 'apitutorial/socialLinks',
	API_TUTORIAL_CONNECT_INSTAGRAM: 'apitutorial/connectInstagram',

	API_NEWS: 'api/news',
	API_BACK_TO_PANEL: 'api/backToNormal',
	API_DASHBOARD: 'api/dashboard',
	API_USER_SESSION: 'api/shopInfo',
	API_HIDE_TUTORIAL_VIDEO: 'api/hideVideo',
	API_VIDEO: 'api/video',
	API_QUESTIONNAIRE: 'api/questionnaire',
	API_TRANSFER_ACCOUNTS: 'api/transferAccounts',
	API_CURRENCY_FILTER: 'api/currencyFilter',
	API_TUS_VIDEO: 'api/tus/video',

	API_COUNTRIES: 'api/countries',
	API_GUS_DATA: 'api/gusData',
	API_SELMO_PRO_FEE: 'api/selmoProFee',
	API_REFUND_STRIPE_PAYMENT: 'api/refundStripePayment',

	API_MESSAGE: 'api/message',
	API_MOBILE_INVITATION: 'api/mobileAppInvitation',

	API_US_STATES: 'api/usStates',
	API_CART_US_STATES: 'apicart/usStates',

	API_PAYMENTS: 'api/payments',
	API_FUNCTIONALITIES: 'api/functionalities',

	API_OWN_PRODUCT_CODE: 'api/productCode',

	API_PAYMENTS_TOTAL_PRICE: 'api/paymentsOverall',
	API_PAYMENTS_EXTEND_TOTAL_PRICE: 'api/paymentsExtendedOverall',

	API_MESSENGER_TAG: 'api/singleTag',
	API_MESSENGER_UNREAD_MESSAGE: 'api/conversationViewed',

	API_STORAGE_PRODUCTS: 'api/products',
	API_BATCH_ORDER_MESSAGES: 'api/batchOrderMessages',
	API_ARCHIVE_OPEN_CART_SURCHARGE: 'api/archiveOpenCartSurcharge',
	API_CLIENT_DELETED_PRODUCTS: 'api/clientDeletedProducts',

	API_PRODUCTS: 'apistorage/products',
	API_PRODUCT_PHOTOS_ORDERS: 'apistorage/productPhotosOrders',
	API_PRODUCTS_LATEST: 'apistorage/latestProducts',
	API_PRODUCTS_DELETED: 'apistorage/productsDeleted',
	API_PRODUCTS_RESERVE: 'apistorage/latestReserveList',
	API_PRODUCT: 'apistorage/products/:id',
	API_PRODUCT_CODE: 'apistorage/selmoCode',
	API_PRODUCT_CURRENT_BASKET: 'apistorage/currentCart',
	API_PRODUCT_RESERVE_LIST: 'apistorage/reserveList',
	API_PRODUCT_FINISHED_CART: 'apistorage/finishedCart',
	API_PRODUCT_ORDER_FROM_RESERVE_LIST: 'apistorage/orderFromReserveList',
	API_PRODUCT_SELMO_CODE_SEARCH: 'apistorage/selmoCodeSearch',
	API_CATEGORIES: 'apistorage/categories',
	API_SEARCH_CATEGORIES: 'apistorage/searchCategories',
	API_CATEGORY_SEO: 'apistorage/categorySeo',
	API_CATEGORIES_WITH_SUBCATEGORIES: 'apistorage/categoriesWithSubCategories',
	API_SHOP_SETTINGS_CATEGORIES: 'apistorage/categoriesSettings',
	API_SHOP_SETTINGS_PRODUCTS: 'apistorage/productsSettings',
	API_BATCH_REMOVE_PRODUCTS: 'apistorage/batchRemoveProducts',
	API_BATCH_DEACTIVATE_PRODUCTS: 'apistorage/batchDeactivateProducts',
	API_BATCH_ACTIVATE_PRODUCTS: 'apistorage/batchActivateProducts',
	API_BATCH_CREATE_ORDERS: 'apistorage/batchCreateOrders',
	API_BATCH_CREATE_PRODUCT_ORDERS: 'apistorage/batchCreateProductOrders',
	API_BATCH_REMOVE_RESERVE_LIST: 'apistorage/batchRemoveFromReserveList',
	API_BATCH_ACTIVE_PRODUCTS_IN_SHOP: 'apistorage/batchActiveShopProducts',
	API_BATCH_ACTIVE_PRODUCTS_IN_APP: 'apistorage/batchActiveMobileAppProducts',
	API_CREATE_PRODUCT_COPY: 'apistorage/copyProduct',
	API_STORAGE_ORDER_PRODUCTS: 'apistorage/orderProducts',
	API_CATEGORY_INFO: 'apistorage/categoryInfo',
	API_STORAGE_CATEGORY_PRODUCTS: 'apistorage/categoryProducts',
	API_ASSIGN_PRODUCTS_TO_CATEGORY: 'apistorage/assignCategory',
	API_COLLECTIONS: 'apistorage/collections',
	API_ASSIGN_PRODUCTS_TO_REELS: 'apistorage/reelsProduct',
	API_STORAGE_REELS: 'apistorage/reels',
	API_COLLECTIONS_ASSIGN_PRODUCTS: 'apistorage/productsCollection',
	API_STORAGE_PRODUCT_TAGS: 'apistorage/productTags',
	API_STORAGE_REMOVE_PRODUCT_TAG: 'apistorage/removeTagFromProduct',
	API_STORAGE_SEND_BATCH_RESERVE_LIST_MESSAGE: 'apistorage/sendBatchReserveListMessage',
	API_STORAGE_SEND_BATCH_RESERVE_LIST_STATUS: 'apistorage/sendBatchReserveListStatus',
	API_STORAGE_AUTOMATIC_RESERVE_LIST: 'apistorage/automaticReserveList',
	API_STORAGE_GENERATE_PZ: 'apistorage/generatePZ',
	API_STORAGE_MANUFACTURERS: 'apistorage/manufacturers',
	API_STORAGE_SEARCH_MANUFACTURERS: 'apistorage/searchManufacturers',
	API_STORAGE_RESET_PRODUCT_SIZE_STOCK: 'apistorage/resetProductSizesStock',
	API_STORAGE_PRINT_PRODUCT_SIZES_QR_CODES: 'apistorage/printProductSizesQrCodes',
	API_STORAGE_ADD_TO_CART_TEMPLATES: 'apistorage/addToCartTemplates',
	API_STORAGE_RESERVE_LIST_TEMPLATES: 'apistorage/reserveListTemplates',
	API_STORAGE_PRODUCT_TEMPLATES: 'apistorage/productTemplates',

	API_STORE_MEDIA_BANNERS: 'apistore/mediaShopBanners',
	API_STORE_MEDIA_BANNERS_MOBILE: 'apistore/mediaShopBannersMobile',
	API_STORE_BANNERS: 'apistore/shopBanners',
	API_STORE_BANNER: 'apistore/shopBanner',
	API_STORE_HOMEPAGE_CATEGORIES: 'apistore/categoriesHomepage',
	API_STORE_HOMEPAGE_CATEGORIES_ORDER: 'apistore/categoriesHomepageOrder',
	API_STORE_GENERAL: 'apistore/storeGeneralSettings',
	API_STORE_DOMAIN: 'apistore/storeSettingsDomain',
	API_STORE_REGULATION: 'apistore/storeTerms',
	API_STORE_PRIVACY: 'apistore/storePolicy',
	API_STORE_INTEGRATIONS: 'apistore/storeIntegrations',
	API_STORE_PROMOTION_ANNOUNCEMENT: 'apistore/promotionAnnouncement',
	API_STORE_PAGES: 'apistore/pages',
	API_STORE_NEWSLETTER: 'apistore/newsletter',
	API_STORE_CLEAR_HOMEPAGE_CACHE: 'apistore/clearHomepageCache',

	API_STORE_START_TRIAL: 'api/storeTrial',

	API_CLIENTS: 'api/clients',
	API_CLIENT_INFO: 'api/clientInfo',
	API_CLIENTS_NOTIFICATIONS: 'api/clientsNotifications',
	API_CLIENTS_SEND_NOTIFICATIONS_STATUS: 'api/sendNotificationsStatus',
	API_CLIENTS_SEND_NOTIFICATIONS: 'api/sendNotifications',
	API_CLIENTS_PAYMENTS_RECEIVED: 'api/clientPaymentReceived',
	API_CLIENTS_PAYMENTS_AUDIO: 'api/clientPaymentAudio',
	API_DPD_PICKUP_PROMO: 'api/dpdPickupPromo',

	API_CLIENT_ORDERS: 'api/clientOrders',
	API_CLIENT_RESERVE_LIST: 'api/clientReserveList',

	API_ORDERS: 'api/shopOrders',
	API_ORDER: 'api/shopOrder',
	API_SINGLE_ORDER: 'api/order',
	API_FILTER_ORDER_COUNTRIES: 'api/orderCountries',
	API_ORDERS_TAGS: 'api/ordersTags',
	API_ORDERS_TAGS_DELETE: 'api/deleteOrdersTag',
	API_NEW_ORDER: 'api/newShopOrder',
	API_ORDER_PRODUCT: 'api/orderProducts',
	API_ORDER_DELIVERY_ADDRESS: 'api/shopOrder/deliveryAddress',
	API_ORDER_PAYMENT_TYPE: 'api/orderPaymentMethods',
	API_ORDER_STATUS: 'api/orderStatus',
	API_ORDER_DETAILS: 'api/orderDetails',
	API_ORDER_SHIPPING_METHODS: 'api/orderShippingMethods',
	API_ORDER_COUNTRIES: 'api/shopCountries',
	API_ORDER_PAID_STATUS: 'api/productPaid',
	API_ORDER_PRODUCTS_PAID_STATUS: 'api/orderProductsPaid',
	API_ORDER_QR_CODE: 'api/orderQrCode',
	API_DIGITAL_PRODUCTS_EMAIL: 'api/digitalProductsEmail',
	API_RETURN_ORDER_PRODUCT: 'api/returnOrderProduct',

	API_ORDER_INPOST_SHIPPMENT_LABEL: 'apishipment/inpostLabel',
	API_ORDER_INPOST_LABEL_READY: 'apishipment/inpostLabelReady',
	API_INPOST_PRINT_LABEL: 'apishipment/inpostPrintLabel',
	API_SHIPMENT_PARCEL_POINTS: 'apishipment/mondialParcelPoints',
	API_SHIPMENT_MONDIAL_RELAY: 'apishipment/mondialRelayLabel',
	API_SHIPMENT_ORLEN_PACZKA: 'apipackage/orlenPaczkaLabel',
	API_SHIPMENT_SHIPMONDO_LABEL: 'apishipment/shipmondoLabel',
	API_SHIPMENT_SHIPMONDO_TEMPLATES: 'apishipment/shipmondoTemplates',
	API_SHIPMENT_DPD_SK_LABEL: 'apishipment/dpdSkLabel',
	API_SHIPMENT_DPD_SK_LABEL_TEMPLATES: 'apishipment/dpdSkTemplates',

	API_PACKAGE_ORLEN_PACZKA_POINTS: 'apipackage/orlenPaczkaPoints',
	API_PACKAGE_DPD_PICKUP_POINTS: 'apipackage/dpdBoxPickup',
	API_PACKAGE_DPD_PICKUP_LABEL: 'apipackage/dpdPickupLabel',
	API_PACKAGE_INPOST_LABEL: 'apipackage/inpostLabel',
	API_PACKAGE_ORLEN_PACZKA_LABEL: 'apipackage/orlenPaczkaLabel',
	API_PACKAGE_ACTIVATE: 'apipackage/activate',
	API_PACKAGE_POINT_ACTIVATION: 'apipackage/pointsActivation',
	API_PACKAGE_BALANCE_TRANSACTION: 'apipackage/balanceTransaction',
	API_PACKAGE_BALANCE_HISTORY: 'apipackage/balanceHistory',
	API_PACKAGE_BALANCE_TRANSFER: 'apipackage/balanceTransfer',
	API_PACKAGE_DPD_PACKAGE_STATUS: 'apipackage/dpdPackageStatus',
	API_PACKAGE_OP_PACKAGE_STATUS: 'apipackage/opPackageStatus',
	API_PACKAGE_INPOST_PACKAGE_STATUS: 'apipackage/inpostPackageStatus',
	API_PACKAGE_BATCH_SP_LABELS: 'apipackage/batchSpLabels',
	API_PACKAGE_BATCH_SP_PRICES: 'apipackage/batchSpPrices',
	API_PACKAGE_BATCH_LABEL_STATUS: 'apipackage/batchLabelsStatus',

	API_ORDER_FURGONETKA_LABEL_READY: 'apishipment/furgonetkaLabelReady',
	API_FURGONETKA_TEMPLATES: 'apishipment/furgonetkaTemplates',
	API_INTEGRATIONS_FURGONETKA: 'apishipment/furgonetkaLabel',

	API_SHIPPYPRO_CARRIERS: 'apishipment/shippyProCarriers',
	API_SHIPPYPRO_LABEL: 'apishipment/shippyProLabel',

	API_PAXY_COUNTRIES: 'apishipment/paxyCountries',
	API_PAXY_OPTIONS: 'apishipment/paxyOptions',
	API_PAXY_LABEL: 'apishipment/paxyLabel',
	API_SHIPMENT_GLS_SK_LABEL: 'apishipment/glsSkLabel',
	API_SHIPMENT_GLS_FR_LABEL: 'apishipment/glsFrLabel',

	API_INTEGRATIONS_FAKTUROWNIA: 'apipayments/fakturowniaInvoicePdf',
	API_FAKTUROWNIA_ORDER_INVOICE: 'apipayments/fakturowniaInvoice',
	API_ORDER_SEND_INVOICE: 'api/sendInvoice',
	API_ORDER_FISCAL_PRINT: 'apipayments/fakturowniaFiscalPrint',

	API_ORDER_SEND_EMAIL_STATUS: 'api/sendEmailStatus',
	API_ORDER_DISABLE_INTEGRATION: 'api/disableIntegration',
	API_CURRENCIES: 'api/orderCurrency',
	API_ORDER_PACKAGE_TYPES: 'api/orderPackageTypes',
	API_ORDER_AUDIO_MESSAGE: 'api/audioMessages',
	API_ORDER_DISCOUNT_CODE: 'api/orderDiscountCode',
	API_ORDER_EMPLOYEES: 'api/orderEmployees',

	API_STATS_BOXES: 'api/statsInfo',
	API_STATS_GO_SERVER: 'api/v2/analytics/stats/orders',
	API_STATS_INFO: 'api/orderStatsInfo',
	API_SELMO_PRO: 'api/selmoPro',
	API_SUB_MENU: 'api/submenu',
	API_CHECK_SELMO_PRO: 'api/checkSelmoPro',
	API_CHECK_POST_PERMISSIONS: 'api/checkPostPermissions',
	API_PUBLISH_VIDEO_POST: 'api/publishVideoPost',
	API_MOBILE_APP_MESSAGE_ACTIVATION_SHARE: 'api/mobileAppMessageActivationShare',
	API_DPD_PROMO_SHARE: 'api/dpdPickupPromoShare',
	API_MOBILE_APP_MESSAGE_ACTIVATION: 'api/mobileAppMessageActivation',

	API_SHIPPING_LIST: 'api/shippingList',
	API_UNPAUSE_ORDER: 'api/unpauseOrder',
	API_PAUSE_ORDER: 'api/pauseOrder',

	API_LIVE: 'apilive/live',
	API_LIVES: 'apilive/lives',
	API_LIVE_TAGS: 'apilive/tags',
	API_LIVE_MESSAGE: 'apilive/message',
	API_LIVE_SEND_BATCH_STATUS: 'apilive/sendBatchStatus',
	API_LIVE_SEND_BATCH_ORDERS_STATUS: 'apilive/batchOrdersStatus',
	API_LIVE_BATCH_ORDERS: 'apilive/batchOrders',
	API_LIVE_RESET_CART: 'apilive/resetCart',
	API_LIVE_RESET_SUMMARY: 'apilive/resetSummary',
	API_LIVE_PRODUCT_SIZES: 'apilive/liveProductSizes',

	API_KNOWLEDGE_BASE_CATEGORIES: 'apibase/categories',
	API_KNOWLEDGE_BASE_ARTICLE: 'apibase/article',
	API_KNOWLEDGE_BASE_CATEGORY_ARTICLES: 'apibase/articlesByCategory',
	API_KNOWLEDGE_SEARCH: 'apibase/search',

	API_LIVE_PRODUCT_HISTORY: 'apilive/productHistory',
	API_LIVE_STORAGE_PRODUCTS: 'apilive/storageProducts',
	API_LIVE_DETAILS_CLIENTS_LIST: 'apilive/clientsLive',
	API_LIVE_DETAILS_SAVED_PRODUCTS_LIST: 'apilive/savedProducts',
	API_LIVE_CREATED_ORDER_INFO: 'apilive/createdOrderInfo',
	API_LIVE_PRODUCTS_EXTRA: 'apilive/liveProductsExtra',
	API_LIVE_INACTIVE_PRODUCT: 'apilive/unactiveProduct',

	API_LIVE_COMMENTS: 'apilive/comments',
	API_LIVE_REVERTED_COMMENTS: 'apilive/revertedComments',
	API_LIVE_SINGLE_CLIENT: 'apilive/singleClient',
	API_LIVE_NEW_COMMENTS: 'apilive/newComments',
	API_LIVE_COMMENTS_REPLY_TO_COMMENT: 'apilive/piano',
	API_LIVE_PORTAL_CART: 'apilive/cart',
	API_LIVE_NO_SHOWN_PRODUCTS: 'apilive/notShownProducts',
	API_LIVE_SHOWN_PRODUCTS: 'apilive/shownProducts',

	API_CART_CART_INFO: 'apicart/cartInfo',
	API_CART_PAYMENT_METHODS: 'apicart/paymentMethods',
	API_CART_SHOP_COUNTRIES: 'apicart/shopCountries',
	API_CART_SHIPPING_ADDRESS: 'apicart/shippingAddress',
	API_CART_SHIPPING_METHODS: 'apicart/shippingMethods',
	API_CART_PACKAGES_TYPES: 'apicart/packagesTypes',
	API_CART_OPEN_CART: 'apicart/openCart',
	API_CART_OVERALL_INFO: 'apicart/overallInfo',
	API_CART_PAID_PRODUCTS: 'apicart/paidProducts',
	API_CART_TERMS: 'apicart/terms',
	API_CART_SHOP_TERMS: 'apicart/shopTerms',
	API_CART_SHOP_POLICY: 'apicart/shopPolicy',
	API_CART_ORDER_PAYMENT: 'apicart/orderPayment',
	API_CART_SHOP_PAYMENT: 'apicart/shopPayment',
	API_CART_OPEN_PACKAGE: 'apicart/openPackage',
	API_CART_DISCOUNT_CODE: 'apicart/discountCode',
	API_CART_DELETE_DISCOUNT_CODE: 'apicart/deleteDiscountCode',
	API_CART_GUEST_ORDER: 'apicart/guestOrder',
	API_CART_ORDER_PAYMENT_PROCESSING: 'apicart/orderPaymentProcessing',
	API_CART_PARCEL_POINTS: 'apicart/mondialParcelPoints',
	API_CART_ORLEN_PACZKA_POINTS: 'apicart/orlenPaczkaPoints',
	API_CART_CART_PRODUCT: 'apicart/cartProduct',
	API_CART_DPD_BOX_PICKUP: 'apicart/dpdBoxPickup',
	API_CART_GLS_POINTS: 'apicart/glsPoints',
	API_CART_LABEL_TRACKING: 'apicart/labelTracking',

	API_BATCH_INVOICES: 'apipayments/batchInvoices',
	API_BATCH_LABELS: 'apishipment/batchLabels',
	API_BATCH_STATUS: 'api/batchStatus',
	API_PRINT_LABELS: 'apishipment/printLabels',
	API_PRINT_INVOICES: 'apipayments/printInvoices',

	API_DISCOUNT_CODES: 'api/discountCodes',
	API_ACTIVE_PAYMENT_METHODS: 'api/activePaymentMethods',
	API_SERVER_LANGUAGE: 'api/serverLanguage',
	API_FAQ: 'api/faq',
	API_CANCEL_ORDER: 'api/cancelOrder',
	API_SET_IS_PAID: 'api/orderPaid',
	API_MESSENGER_TEMPLATE: 'api/messengerTemplate',
	API_ANALYTICS_SUGGESTION: 'api/analyticsSuggestion',

	API_SHOP_CATEGORIES: 'apishop/categories',
	API_STORAGE_CATEGORY_ORDER: 'apistorage/categoryOrder',
	API_STORAGE_PRODUCT_SIZE_STOCK: 'apistorage/productSizesStock',
	API_STORAGE_PRODUCT_SIZE_WAREHOUSE: 'apistorage/productSizesWarehouse',
	API_STORAGE_PRODUCT_ORDER: 'apistorage/productOrder',
	API_STORAGE_DIGITAL_PRODUCT: 'apistorage/digitalProduct',
	API_STORAGE_MOBILE_REELS: 'apistorage/mobileReels',
	API_STORAGE_PRODUCT_SIZE_PRICE: 'apistorage/productSizesPrice',

	API_SETTINGS_GENERAL: 'apisettings/general',
	API_SETTINGS_CHANGE_PASSWORD: 'apisettings/changePassword',
	API_SETTINGS_FAKTUROWNIA: 'apisettings/fakturownia',
	API_SETTINGS_FURGONETKA: 'apisettings/furgonetka',
	API_SETTINGS_FURGONETKA_REDIRECT: 'apisettings/furgonetkaRedirect',
	API_SETTINGS_BLUEMEDIA: 'apisettings/bmPaymentsInfo',
	API_SETTINGS_INPOST: 'apisettings/inpost',
	API_SETTINGS_TRANSFER: 'apisettings/transfer',
	API_SETTINGS_LIVE_PLATFORM: 'apisettings/livePlatform',
	API_SETTINGS_PAYMENT_PICKUP: 'apisettings/paymentPickup',
	API_SETTINGS_PAYMENT_PAYPAL: 'apisettings/paymentPaypal',
	API_SETTINGS_PAYMENT_PAYPAL_ONBOARDING: 'apisettings/paypalOnboarding',
	API_SETTINGS_INVOICE_DATA: 'apisettings/invoiceData',
	API_SETTINGS_BILLING: 'apisettings/billing',
	API_SETTINGS_BILLING_INVOICE: 'apisettings/billingInvoice',
	API_SETTINGS_PAYMENT_LUSOPAY: 'apisettings/lusopay',
	API_SETTINGS_PAYMENT_MOBILEPAY: 'apisettings/mobilepay',
	API_SETTINGS_PAYMENT_STRIPE: 'apisettings/stripePaymentsInfo',
	API_SETTINGS_PAYMENT_STRIPE_REAUTH: 'apisettings/stripeReauth',
	API_SETTINGS_PAYMENT_SHOP_PICKUP: 'apisettings/paymentShopPickup',
	API_SETTINGS_TERMS: 'apisettings/terms',
	API_SETTINGS_PAYMENTS_INFO: 'apisettings/paymentsInfo',
	API_SETTINGS_SHIPPING: 'apisettings/shipping',
	API_SETTINGS_SHIPPING_COUNTRY: 'apisettings/shippingCountry',
	API_SETTINGS_DISCOUNT_CODES: 'apisettings/discountCodes',
	API_SETTINGS_PACKAGES: 'apisettings/packages',
	API_SETTINGS_VOICE_MESSAGES: 'apisettings/voiceMessages',
	API_SETTINGS_VOICE_MEDIA: 'apisettings/voiceMedia',
	API_SETTINGS_NIP_CHOOSER: 'apisettings/nipChooser',
	API_SETTINGS_SHIPPYPRO_TEMPLATE: 'apisettings/shippyproTemplate',
	API_SETTINGS_SHIPPYPRO: 'apisettings/shippypro',
	API_SETTINGS_MONDIAL: 'apisettings/mondial',
	API_SETTINGS_ORLEN: 'apisettings/orlenPaczka',
	API_SETTINGS_SHIPMONDO: 'apisettings/shipmondo',
	API_SETTINGS_WOOCOMMERCE: 'apisettings/woocommerce',
	API_SETTINGS_SHOPIFY: 'apisettings/shopify',
	API_SETTINGS_SHOPIFY_DISCONNECT: 'apisettings/shopifyDisconnect',
	API_SETTINGS_CALLBACK_SHOPIFY: 'apisettings/callbackShopify',
	API_SETTINGS_PRESTASHOP: 'apisettings/prestaShop',
	API_SETTINGS_DPD_SK: 'apisettings/dpdSk',
	API_SETTINGS_DPD_SK_TEMPLATE: 'apisettings/dpdSkTemplates',
	API_SETTINGS_SYNC_WOOCOMMERCE: 'apisettings/syncWoocommerce',
	API_SETTINGS_SYNC_SHOPIFY: 'apisettings/syncShopify',
	API_SETTINGS_SYNC_PRESTASHOP: 'apisettings/syncPrestaShop',
	API_SETTINGS_MESSENGER_TEMPLATES: 'apisettings/messengerTemplates',
	API_SETTINGS_MESSENGER_TEMPLATES_ADD_TO_CART: 'apisettings/addToCartTemplates',
	API_SETTINGS_MESSENGER_TEMPLATES_ADD_TO_RESERVE_LIST: 'apisettings/reserveListTemplates',
	API_SETTINGS_MESSENGER_TEMPLATES_PRODUCT_HAS_VARIANTS: 'apisettings/productHasVariantsTemplates',
	API_SETTINGS_MESSENGER_TEMPLATES_WRONG_CODE: 'apisettings/wrongCodeTemplates',
	API_SETTINGS_FACEBOOK: 'apisettings/facebook',
	API_SETTINGS_FACEBOOK_CONFIGURE: 'apisettings/facebookConfigure',
	API_SETTINGS_INSTAGRAM: 'apisettings/instagram',
	API_SETTINGS_INSTAGRAM_CONFIGURE: 'apisettings/instagramConfigure',
	API_SETTINGS_NOTIFICATION: 'apisettings/orderNotifications',
	API_SETTINGS_PAXY: 'apisettings/paxy',
	API_SETTINGS_SHIPPING_COUNTRIES: 'apisettings/shippingCountries',
	API_SETTINGS_STRIPE_COUNTRY: 'apisettings/stripeCountry',
	API_SETTINGS_CANCEL_SUBSCRIPTION: 'apisettings/cancelSubscription',
	API_SETTINGS_BM_TRANSFER_INFO: 'apisettings/bmTransferInfo',
	API_SETTINGS_FACEBOOK_POSTS: 'apisettings/facebookPosts',
	API_SETTINGS_GLS_SK: 'apisettings/glsSk',
	API_SETTINGS_GLS_FR: 'apisettings/glsFr',
	API_ACTIVATE_AUTOMATIC_TRANSFER: 'apisettings/autopayNumbers',
	API_SETTINGS_RESERVE_LIST_AUTOMATIC_STATS: 'apisettings/reserveListAutomaticStats',
	API_SETTINGS_RESERVE_LIST_AUTOMATIC: 'apisettings/reserveListAutomatic',
	API_SETTINGS_PAYPAL_CONFIG: 'apisettings/paypalConfig',
	API_SETTINGS_ORDER_CANCELLATION_ORDERS: 'apisettings/cancelledOrders',
	API_SETTINGS_CLOSED_OPEN_PACKAGES: 'apisettings/closedOpenPackages',
	API_SETTINGS_ORDER_CANCELLATION: 'apisettings/orderCancellation',
	API_SETTINGS_ORDER_PAYMENT_REMINDER: 'apisettings/orderPaymentReminder',
	API_SETTINGS_OPEN_PACKAGE_CLOSING: 'apisettings/openPackageClosing',
	API_SETTINGS_PRODUCT_QR_CODE: 'apisettings/settingsProductQRCode',
	API_SETTINGS_AUTOMATE_SUMMARY: 'apisettings/automateSummary',
	API_SETTINGS_LAST_AUTOMATE_ACTIVITIES: 'apisettings/lastAutomateActivities',
	API_SETTINGS_ADDITIONAL_PAYMENTS: 'apisettings/additionalPayments',
	API_SETTINGS_INTEGRATIONS: 'apisettings/integrations',
	API_SETTINGS_SEARCH: 'apisettings/settingsSearch',

	API_EXPORT_NEWSLETTER_LIST_XLS: 'apiexport/newsletterListXlsx',
	API_EXPORT_PRODUCTS_LIST_XLS: 'apiexport/productsXlsx',
	API_EXPORT_STORAGE_ORDERED_PRODUCTS_PDF: 'apiexport/storageOrderedProductsPdf',
	API_EXPORT_STORAGE_ORDERED_PRODUCTS_XLS: 'apiexport/storageOrderedProductsXlsx',
	API_EXPORT_STORAGE_BOUGHT_PRODUCT_CLIENTS_PDF: 'apiexport/storageBoughtProductClientsPdf',
	API_EXPORT_STORAGE_CURRENT_PRODUCT_CLIENTS_PDF: 'apiexport/storageCurrentProductClientsPdf',
	API_EXPORT_STORAGE_ORDERED_PRODUCTS_CLIENTS_PDF: 'apiexport/storageOrderedProductsClientsPdf',
	API_EXPORT_BOUGHT_PRODUCT_PDF: 'apiexport/storageBoughtProductPdf',
	API_EXPORT_STORAGE_RESERVE_LIST_PDF: 'apiexport/storageReserveListPdf',
	API_EXPORT_STORAGE_RESERVE_LIST_XLS: 'apiexport/storageReserveListXlsx',
	API_EXPORT_ALL_ORDERS_PDF: 'apiexport/ordersPdf',
	API_EXPORT_ALL_ORDERS_XLS: 'apiexport/ordersXlsx',
	API_EXPORT_ORDER_PDF: 'apiexport/orderPdf',
	API_EXPORT_PRODUCT_INFO: 'apiexport/productInfo',
	API_EXPORT_LIVE_PDF: 'apiexport/livePdf',
	API_EXPORT_LIVE_PRODUCTS_PDF: 'apiexport/liveProducts',
	API_EXPORT_LIVE_CLIENTS_PDF: 'apiexport/liveClients',
	API_EXPORT_LIVE_SALES_STATEMENT_XLS: 'apiexport/liveSummaryXlsx',
	API_EXPORT_LIVE_STOS_PDF: 'apiexport/stosPdf',
	API_EXPORT_LIVE_TIM_PDF: 'apiexport/timLivePdf',
	API_EXPORT_LIVE_TNK_PDF: 'apiexport/tnkLive',
	API_EXPORT_BIG_XLSX_FILE: 'apiexport/bigXlsxFile',
	API_EXPORT_EXPORT_FILES: 'apiexport/exportFiles',
	API_STORAGE_MASS_PRODUCT_CARDS: 'apistorage/massProducts',
	API_EXPORT_PRODUCTS_CARD_INFO: 'apiexport/productsInfo',
	API_EXPORT_PRINT_SIZES_QR_CODES: 'apiexport/printSizesQrCodes',
	API_EXPORT_SELMO_PACZKA_BALANCE_XLSX: 'apiexport/selmoPaczkaBalanceXlsx',
	API_EXPORT_ORDERS_QR_PDF: 'apiexport/ordersQrPdf',


	API_MOBILE_QR_CODE: 'apimobile/qrCode',
	API_MOBILE_START_LIVE: 'apimobile/startLive',

	API_MOBILE_ACTIVE_PRODUCT: 'apimobile/activeProduct',
	API_MOBILE_MOBILE_LIVES: 'apimobile/mobileLives',

	API_MOBILE_CLIENTS_NOTIFICATIONS: 'api/mobileClientsNotifications',
	API_MOBILE_NOTIFICATIONS: 'api/mobileNotifications',
	API_MOBILE_SEND_NOTIFICATIONS_STATUS: 'api/sendMobileNotificationsStatus',
	API_MOBILE_APP: 'api/mobileApp',

	API_MEDIA_DELETE: 'api/mediaDelete',
	API_MEDIA_FILE: 'api/mediaFile',

	API_SUMMER_PROMO: 'api/summerPromo',
	API_BLACK_WEEK_PROMO: 'api/blackWeekPromo',
	API_SUMMER_PROMO_LANDING: 'api/summerPromoLanding',
	API_BLACK_WEEK_PROMO_LANDING: 'api/blackWeekPromoLanding',

	API_INSTAGRAM_POSTS: 'apiinstagram/igPosts',
	API_INSTAGRAM_AUTOMATIONS: 'apiinstagram/igAutomations',
	API_INSTAGRAM_AUTOMATIONS_INTERACTIONS: 'apiinstagram/igAutomationInteractions',
	API_INSTAGRAM_AUTOMATIONS_INTERACTIONS_COUNTER: 'apiinstagram/igAutomationInteractionsCounter',

	API_CHECKOUT_INFO: 'apicheckout/checkoutInfo',
	API_CHECKOUT_SHIPPING_METHODS: 'apicheckout/shippingMethods',
	API_CHECKOUT_SHOP_COUNTRIES: 'apicheckout/shopCountries',
	API_CHECKOUT_OVERALL: 'apicheckout/overall',
	API_CHECKOUT_NEWSLETTER: 'apicheckout/newsletterInfo',

	API_WAREHOUSE_ORDER: 'apiwarehouse/order',
	API_WAREHOUSE_ORDER_NOTE: 'apiwarehouse/orderNote',
	API_WAREHOUSE_ORDER_PRODUCTS: 'apiwarehouse/orderProducts',
	API_WAREHOUSE_PRODUCT: 'apiwarehouse/product',
	API_WAREHOUSE_SCAN_PRODUCT: 'apiwarehouse/scanProduct',
	API_WAREHOUSE_SHOP_PRODUCT_TO_ORDER: 'apiwarehouse/shopProductToOrder',
	API_WAREHOUSE_SHELVES: 'apiwarehouse/shelves',
	API_WAREHOUSE_SHELVES_AUTOMATIC: 'apiwarehouse/shelvesAutomatic',
	API_WAREHOUSE_SHELF_QR_CODE: 'apiwarehouse/shelfQrCode',
	API_WAREHOUSE_UNSCAN_PRODUCT: 'apiwarehouse/unscanProduct',
	API_WAREHOUSE_EXPENDITURE: 'apiwarehouse/expenditure',
	API_WAREHOUSE_EXPENDITURES: 'apiwarehouse/expenditures',
	API_WAREHOUSE_WAREHOUSE_EXPENDITURES: 'apiwarehouse/warehouseExpenditures',
	API_WAREHOUSE_TAKE_OFF_PRODUCT: 'apiwarehouse/takeOffProduct',
	API_WAREHOUSE_SHELVES_QR_CODE: 'apiwarehouse/shelvesQrCodes',
	API_WAREHOUSE_ORDER_ID: 'apiwarehouse/orderId',
	API_WAREHOUSE_UNPACK_ALL_ORDERS: 'apiwarehouse/unpackAllOrderProducts',
	API_WAREHOUSE_SHOP_PRODUCT: 'apiwarehouse/shopProduct',

	API_MOBILE_PUBLIC_CONFIRM_EMAIL: 'apimobilepublic/confirmEmail',
	API_MOBILE_PUBLIC_RESET_PASSWORD: 'apimobilepublic/resetPassword',

	API_MOBILE_PUBLIC_REDIRECT_PAGE: 'apimobilepublic/mobilePath',

	API_VIDEO_SOCIAL_VIDEOS: 'apivideo/socialVideos',
	API_VIDEO_CANCEL_POST: 'apivideo/cancelFbPost',
	API_VIDEO_MOBILE_NOTIFICATIONS: 'apivideo/mobilePostNotifications',
	API_VIDEO_SEND_NOTIFICATIONS_STATUS: 'apivideo/sendPostNotificationsStatus',
	API_VIDEO_CANCEL_POST_NOTIFICATIONS: 'apivideo/cancelPostNotifications',
	API_VIDEO_APP_USERS: 'apivideo/appUsers',
	API_VIDEO_CONNECTED_USERS: 'apivideo/connectedUsers',
	API_VIDEO_APP_USERS_CONNECT: 'apivideo/appUserConnect',
	API_VIDEO_APP_USER_DELETE: 'apivideo/appUserDelete',
	API_VIDEO_SOCIAL_SALES_STATS: 'apivideo/socialSalesStats',
	API_VIDEO_CHOSEN_TEMPLATE: 'apivideo/chosenTemplate',
	API_VIDEO_DESCRIPTION_TEMPLATES: 'apivideo/descriptionTemplates',
	API_VIDEO_FACEBOOK_POSTS: 'apivideo/facebookPosts',
	API_VIDEO_FACEBOOK_IMPORT_STATUS: 'apivideo/facebookImportStatus',
	API_VIDEO_COPY_SOCIAL_VIDEO: 'apivideo/copySocialVideo',
	API_VIDEO_CATEGORY_PRODUCTS: 'apivideo/categoryProducts',

	API_PERMISSIONS_PERMISSIONS: 'apipermissions/permissions',
	API_PERMISSIONS_EMPLOYEES: 'apipermissions/employees',
	API_PERMISSIONS_EMPLOYEE: 'apipermissions/employee',
	API_PERMISSIONS_INVITATIONS: 'apipermissions/invitations',
	API_PERMISSIONS_OVERALL_STATS: 'apipermissions/overallStats',
	API_PERMISSIONS_STATISTICS: 'apipermissions/employeesStats',
	API_PERMISSIONS_EMPLOYEES_ACTIONS: 'apipermissions/employeesActions',
	API_PERMISSIONS_EMPLOYEE_HISTORY: 'apipermissions/employeeHistory',
	API_PERMISSIONS_EMPLOYEE_INFO: 'apipermissions/employeeInfo',
	API_PERMISSIONS_ORDER_HISTORY: 'apipermissions/orderHistory',
	API_PERMISSIONS_PRODUCT_HISTORY: 'apipermissions/productHistory',

	API_SHOPIFY_PUBLIC_CONNECTION: 'apishopifypublic/connection',
	API_SHOPIFY_PUBLIC_CONNECTION_HANDLE: 'apishopifypublic/connectionHandle',
}
