import React from 'react';
import {CONFIRM_MODAL_TYPES} from "../../clients/confirmModals/ConfirmModalTypes";
import {setConfirmModalToggleVisibility, updateLivesDetailsInfo} from "../../LiveDetailsActions";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {useDispatch, useSelector} from "react-redux";
import {services} from "../../../../../../RestServices";
import {showAlert} from "../../../../../shared/components/alert/AlertActions";
import Modal from "react-bootstrap/Modal";
import useFormLocal from "../../../../../../../src_shared/hooks/useFormLocal";
import SwitchField from "../../../../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../../../shared/Loader";
import CreateBatchOrdersModel from "../../../../../../../modules/models/lives/CreateBatchOrdersModel";

const BatchOrdersModal = () => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const {clientsConfirmModal, liveInfo} = useSelector((state) => state.lives.details)
    const hideModal = () => dispatch(setConfirmModalToggleVisibility(null));

    const afterSubmit = () => {
        dispatch(showAlert('yuhuLabel', 'actionsHaveBeenSuccessfullyRequestedLabel'))
        dispatch(updateLivesDetailsInfo({...liveInfo, waitingForOrders: true}))
        hideModal();
    }

    const [{onSave, isWaiting, getFieldProps}] = useFormLocal({
        rest: `${services.API_LIVE_BATCH_ORDERS}/${liveInfo.id}`,
        model: CreateBatchOrdersModel,
        afterSubmit,
        initialAutoLoad: false,
    });

    return (
        <Modal
            dialogClassName="default-modal sm-size"
            show={CONFIRM_MODAL_TYPES.CREATE_ALL_ORDERS === clientsConfirmModal.modalType}
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="text-xl">{getLangText('createOrdersLabel')}</div>
                    <div className="sub-title">
                        {getLangText('createOrdersInThisLiveSubTitleModalLabel')}
                    </div>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="form-info-box w-100">
                    <div>
                        <i className="icon-info-c"/>
                    </div>
                    <div>
                        <div className="title">{getLangText('liveHasAlreadyEndedLabel')}</div>
                        <div className="desc">{getLangText('liveHasAlreadyEndedSubLabel')}</div>
                    </div>
                </div>
                <div className="font-bold mb-2 mt-4">{getLangText('additionalOptionsLabel')}</div>
                <div className='space-y-2'>
                    <div>
                        <SwitchField
                            {...getFieldProps('cart')}
                            className="switch-form align-items-center inline-label big-label light-version mb-0 mt-0 p-3 border border-gray-200 rounded-md w-full"
                            label={getLangText('sendBasketStatesLabel')}
                            subLabel={getLangText('clientsReceivedMessagesWithProductsLabel')}
                            id="cart"
                        />
                    </div>
                    <div>
                        <SwitchField
                            {...getFieldProps('summary')}
                            className="switch-form align-items-start inline-label big-label light-version mb-0 mt-0 p-3 border border-gray-200 rounded-md w-full"
                            label={getLangText('sendSummariesLabel')}
                            subLabel={getLangText('clientsReceivedMessagesWithProductsAndOrderLabel')}
                            id="summary"
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    onClick={onSave}
                    type="button"
                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                >
                    {isWaiting && <Loader isLoading />}
                    {getLangText('executeLabel')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default BatchOrdersModal;
