import React, {useState} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import {services} from "../../../../../RestServices";
import Modal from "react-bootstrap/Modal";
import useFetchData from "../../../../../../src_shared/hooks/useFetchData";
import SearchInput from "../../../../shared/components/form/SearchInput";
import ComponentLoader from "../../../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import SearchPeopleDropdown from "./SearchPeopleDropdown";
import PersonItem from "./PersonItem";
import {SHOP_ORIGIN_PL} from "../../../../shared/enums/ShopOrigins";
import useAssetsLang from "../../../../shared/helpers/useAssetsLang";

const MobileAppPermissionsModal = ({modalVisible, hideModal}) => {
    const {getLangText} = useLang();
    const [searchText, setSearchText] = useState('');
    const [showImageModal, setShowImageModal] = useState(false);
    const lang = useAssetsLang();
    const updateUsers = async (res, data) => {
        setUsers((prevState) => prevState.filter((i) => i.app_client_id !== data.app_user_id))
        await fetchConnectedUsers();
    }

    const updateConnectedUsers = async (res, data) => {
        setConnectedUsers((prevState) => prevState.filter((i) => i.app_client_id !== data.app_user_id))
    }

    const [{
        data: connectedUsers,
        isLoading: isLoadingConnectedUsers,
        fetchData: fetchConnectedUsers,
        setData: setConnectedUsers
    }] = useFetchData({
        rest: services.API_VIDEO_CONNECTED_USERS,
        initialData: [],
    })

    const [{
        data: usersData,
        isLoading: isLoadingUsers,
        fetchData: fetchUsers,
        setData: setUsers,
        setIsLoading: setIsLoadingUsers
    }] = useFetchData({
        rest: services.API_VIDEO_APP_USERS,
        initialData: [],
        initialAutoLoad: false
    })

    const search = {
        updateSearchText: (text) => {
            setIsLoadingUsers(true)
            setSearchText(text);
        },
        searchText,
        callSearch: fetchUsers,
    }

    return (
        <Modal
            dialogClassName="default-modal"
            show={modalVisible}
            onHide={hideModal}
            backdropClassName={showImageModal ? 'with-extra-modal' : ''}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText('howToAddVideosByApp')}
                    <div className="sub-title">{getLangText('addVideoFaster')}</div>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body className="pt-3 pb-5 assign-products-body">
                {lang === SHOP_ORIGIN_PL &&
                    <div className="selmo-video-wrapper mb-3">
                        <iframe
                            frameBorder="0" allowFullScreen="" scrolling="no"
                            allow="autoplay;fullscreen"
                            src={`/assets/videos/${lang}/adding-posts-app.mp4`}
                            style={{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: '0px',
                                top: '0px'
                            }}
                        />
                    </div>
                }
                <div className="space-y-4 mb-4 bg-[#F0FAFF] rounded-[8px] p-3">
                    <div className="flex items-start">
                        <div>
                            <i className="icon-tick-c text-[18px] min-w-[18px] mr-2 text-[#5FA5FA]"/>
                        </div>
                        <div>
                            <div
                                className="text-[#101827] font-medium text-sm"
                                dangerouslySetInnerHTML={{__html: getLangText('grantAccessPersonWho')}}/>
                        </div>
                    </div>
                    <div className="flex items-start">
                        <div>
                            <i className="icon-tick-c text-[18px] min-w-[18px] mr-2 text-[#5FA5FA]"/>
                        </div>
                        <div>
                            <div
                                className="text-[#101827] font-bold text-sm"
                                dangerouslySetInnerHTML={{__html: getLangText('optionWillBeVisibleInApp')}}/>
                            <button
                                type="button"
                                className="text-sm font-medium underline"
                                onClick={() => setShowImageModal(true)}
                            >
                                {getLangText('seeWhereWillBeOption')}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="font-bold mb-2">{getLangText('grantPermissionsToVideo')}</div>
                <SearchInput
                    className="mb-2 big-size"
                    placeholder={getLangText('textNameAndSurnameLabel')}
                    search={search}
                />
                <SearchPeopleDropdown
                    isLoadingUsers={isLoadingUsers}
                    usersData={usersData}
                    searchText={searchText}
                    updateConnectedUsers={updateConnectedUsers}
                    updateUsers={updateUsers}
                />
                <div className="border-t pt-3 mt-3">
                    <div className="font-bold mb-2">{getLangText('accessGranted')}</div>
                    {isLoadingConnectedUsers ?
                        <ComponentLoader style={{minHeight: '200px'}}/> :
                        !!connectedUsers.length ?
                            <div className="max-h-[250px] overflow-y-auto -mx-[8px]">
                                {connectedUsers.map((item) => (
                                    <PersonItem
                                        item={item}
                                        key={item.app_client_id}
                                        updateConnectedUsers={updateConnectedUsers}
                                        updateUsers={updateUsers}
                                    />
                                ))}
                            </div>
                            :
                            <div className="font-medium text-sm text-desc">{getLangText('noGrantedPermission')}</div>
                    }
                </div>
            </Modal.Body>
            {showImageModal &&
                <Modal
                    className="higher-index"
                    backdropClassName="higher-index"
                    dialogClassName="default-modal preview-img-modal"
                    show={showImageModal}
                    onHide={() => setShowImageModal(false)}
                    contentClassName="bg-transparent"
                >
                    <button
                        onClick={() => setShowImageModal(false)}
                        type="button"
                        className="fixed animate-[fadeIn_.3s_ease_forwards_.4s] opacity-0 top-3 right-3 w-[40px] h-[40px] text-[10px] rounded-full bg-white text-[#3C61EA] flex items-center justify-center"
                    >
                        <i className="icon-cross"/>
                    </button>
                    <img
                        className="sm:w-auto w-full max-w-[700px] mx-auto max-h-[calc(100vh-60px)] sm:rounded-xl"
                        src="/assets/images/managePostsPreview.png"
                        alt="preview"
                    />
                </Modal>
            }
        </Modal>
    );
};

export default MobileAppPermissionsModal;
