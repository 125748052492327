import {useSelector} from "react-redux";
import {
    SHOP_ORIGIN_ES,
    SHOP_ORIGIN_FR,
    SHOP_ORIGIN_IT,
    SHOP_ORIGIN_PL,
    SHOP_ORIGIN_PT,
    SHOP_ORIGIN_SK, SHOP_ORIGIN_US
} from "../enums/ShopOrigins";

const useAssetsLang = () => {
    const {lang} = useSelector((state) => state.i18n);

    if (lang === SHOP_ORIGIN_PL ||
        lang === SHOP_ORIGIN_IT ||
        lang === SHOP_ORIGIN_FR ||
        lang === SHOP_ORIGIN_SK ||
        lang === SHOP_ORIGIN_PT ||
        lang === SHOP_ORIGIN_ES
    ) return lang;

    return SHOP_ORIGIN_US;
};

export default useAssetsLang;