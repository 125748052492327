import React, {useEffect, useRef, useState} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import {useHistory} from "react-router-dom";

const TrackingInput = ({trackingId, data, isLoading}) => {
    const {getLangText} = useLang();
    const inputRef = useRef(null)
    const [inputValue, setInputValue] = useState('');
    const history = useHistory();

    const onSave = (e) => {
        e.preventDefault();
        history.push(`/tracking/${inputValue}`);
    }

    const preparedImage = () => {
        if (data.type === 'inpost') {
            return (
                <img
                    className="h-[25px]"
                    src="/assets/images/inpost.svg" alt=""/>
            )
        }
        if (data.type === 'op') {
            return (
                <img
                    className="w-[35px]"
                    src="/assets/images/svg/orlen-paczka.svg" alt=""/>
            )
        }

        if (data.type?.includes('dpd')) {
            return (
                <img
                    className="h-[25px]"
                    src="/assets/images/svg/dpd-logo.svg" alt=""/>
            )
        }
        if (data.type?.includes('gls')) {
            return (
                <img
                    className="w-[40px]"
                    src="/assets/images/gls.png" alt=""/>
            )
        }
        return null;
    }

    useEffect(() => {
        if (trackingId) {
            setInputValue(trackingId);
        }
    }, [trackingId])

    return (
        <div className="bg-[#fff] rounded-[12px] sm:rounded-[20px] p-6 sm:p-12 shadow-default-small">
            <div
                className="text-2xl sm:text-[28px] font-bold whitespace-pre-wrap tracking-page-title"
                dangerouslySetInnerHTML={{__html: getLangText('checkYourPackageStatus')}}/>
            <form onSubmit={onSave}>
                <div className="flex items-center mt-sm-4 mt-3">
                    <div className="grow flex relative">
                        <input
                            ref={inputRef}
                            name="tracking_id"
                            onChange={(e) => setInputValue(e.target.value)}
                            required
                            className="border flex items-center text-sm focus:outline-none focus:border-[#3C61EA] transition-all grow sm:text-lg border-[#E3DFDF] p-[14px] rounded-[8px] pr-4 relative"
                            value={inputValue}
                        />
                        <button
                            type="button"
                            onClick={() => {
                                setInputValue('');
                                inputRef.current.focus();
                            }}
                            className="hover:text-[#000] transition-all text-desc sm:p-3 px-1.5 text-[8px] sm:text-[10px] absolute right-1 top-1/2 -translate-y-1/2"
                        >
                            <i className="icon-cross"/>
                        </button>
                    </div>
                    <button
                        style={{borderRadius: 8}}
                        type="submit"
                        className="button self-stretch tracking-search primary big-size ml-sm-3 ml-2 px-sm-4 px-4 py-sm-3 py-2 sm:h-auto h-[50px]"
                    >
                        <i className="text-lg icon-magnifier sm:hidden"/>
                        {/*{isWaiting && <Loader isLoading/>}*/}
                        <div className="sm:block hidden">{getLangText('findPackage')}</div>
                    </button>
                </div>
            </form>
            {data.type &&
                <div className='flex items-center sm:justify-start justify-center mt-3'>
                    <div className="text-[#9CA3AF] text-sm font-medium mr-2">
                        {getLangText('shipmentsIsHandledBy')}
                    </div>
                    {isLoading ?
                        <div className="animate-pulse h-[25px] bg-gray-200 rounded-md dark:bg-gray-700 w-[70px]"/> :
                        preparedImage()
                    }
                </div>
            }
        </div>
    );
};

export default TrackingInput;
