import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

export class AssignEmployeeModel extends Model {
	getModel() {
		return {
			employee_id: null,
		};
	}

	buildDTO(data) {
		return {
			id: data.employee_id,
		};
	}
}

export default new AssignEmployeeModel();
