import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import useLogout from "../../shared/hooks/useLogout";

const defaultImg = <img width={30} className="mx-auto mb-2" src="/assets/images/svg/plus-icon.svg" alt="plus icon" />
const CreateShopLayout = ({children, title, desc, Img = defaultImg, withAnimation = true, headerClassName = 'mt-10 sm:mt-20 sm:mb-12 mb-8', titleClassName = 'sm:text-2xl text-xl'}) => {

    const {getLangText} = useLang();
    const {logout} = useLogout();

    return (
        <div className={`bg-[#eff2f6] sm:py-0 py-6 sm:px-0 px-6 sm:min-h-auto min-h-full sm:h-[100vh] w-[100vw] flex sm:items-center sm:justify-center`}>
            <button
                onClick={logout}
                type="button"
                className="absolute bottom-2 left-2 text-desc text-xs underline"
            >
                {getLangText('changeAccountLabel')}
            </button>
            <div className={`w-full sm:my-0 my-auto md:px-0 px-6 rounded-[12px] shadow-default bg-white md:w-[800px] min-h-[450px] md:min-h-[600px] flex flex-col ${withAnimation ? 'animate-tutorialSlideAndFadeIn' : ''}`}>
                {title &&
                    <div className={`text-center animate-tutorialSlideAndFadeInDelay1 opacity-0 ${headerClassName}`}>
                        {Img && Img}
                        <h1 className={`font-bold ${titleClassName}`} dangerouslySetInnerHTML={{__html: getLangText(title)}} />
                        <h2 className="text-[#9CA3AF] text-sm font-medium" dangerouslySetInnerHTML={{__html: getLangText(desc)}} />
                    </div>
                }
                {children}
            </div>
        </div>
    );
};

export default CreateShopLayout;
