import React, {useEffect} from 'react';
import {
    findOrderTableColumnById, ORDER_TABLE_COLUMN,
} from "./OrderTableColumnEnums";
import useTableColumn from "./useTableColumn";
import {getStateRoot as getFiltersStateRoot} from "../../../filters/filtersReducer";
import SelectedRow from "../selectedRow/SelectedRow";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {batch, useDispatch, useSelector} from "react-redux";
import {
    BUTIK_MADAME_SHOP_ID, LADY_WYJATKOWE_KREACJE_SHOP_ID, MAGIC_ROSE_SHOP_ID, SHOP_BAZAR_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID
} from "../../../../../../src_shared/enums/TrustedShopsIds";
import {useLocation} from "react-router-dom";
import ListActions from "../../ListActions";

const TableHeader = ({updateSortOptions, getSortClass, selectAllItems, checkIfSomeExist, selectedItems}) => {

    const {preparedTableColumn} = useTableColumn(ORDER_TABLE_COLUMN, 'tableColumn', getFiltersStateRoot)
    const {getLangText} = useLang();
    const isSelected = !!selectedItems.length;
    const {userData} = useSelector((state) => state.session);
    const dispatch = useDispatch();
    const colSpan = isSelected ? ((+userData.id === BUTIK_MADAME_SHOP_ID || +userData.id === TEST_ACCOUNT_SHOP_ID) ? +preparedTableColumn.length + 2 : preparedTableColumn.length) : 1

    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        const spOrdersArray = searchParams.getAll('orders[]');
        const spOrdersObjects = spOrdersArray.map(order => ({id: order}));

        const all_orders = searchParams.get('all_orders');
        const failed_orders = searchParams.get('failed_orders');
        const success_orders = searchParams.get('success_orders');
        const waiting_for_labels = !!+searchParams.get('waiting_for_labels');
        const log_id = searchParams.get('log_id');

        const item = {
            all_orders,
            failed_orders,
            success_orders,
            waiting_for_labels,
            log_id,
        }

        if (!!spOrdersArray.length) {
            batch(() => {
                dispatch(ListActions.selectedItems.updateSelection(spOrdersObjects))
                dispatch(ListActions.afterGenerate(item, false))
                dispatch(ListActions.setModalVisible('GENERATE_LABEL_MODAL'))
            })
        }

    }, [location]);

    return (
        <thead>
        <tr className={isSelected ? 'sticky-row' : ''}>
            <SelectedRow
                colSpan={colSpan}
                selectAllItems={selectAllItems}
                selectedItems={selectedItems}
                isSelected={isSelected}
                checkIfSomeExist={checkIfSomeExist}
            />
            {!isSelected && preparedTableColumn.map((column) => (
                column === 'client' || column === 'label' || column === 'invoice' || column === 'tags' ?
                    <th
                        key={column}
                        className={`${findOrderTableColumnById(column)?.className}`}
                    >
                        {getLangText(findOrderTableColumnById(column)?.label)}
                    </th> :
                    <th
                        key={column}
                        name={column}
                        onClick={updateSortOptions}
                        className={`${findOrderTableColumnById(column)?.className} ${getSortClass(column)}`}
                    >
						<span name={column}>
							{getLangText(findOrderTableColumnById(column)?.label)}
						</span>
                    </th>
            ))}
            {!isSelected && (+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === LADY_WYJATKOWE_KREACJE_SHOP_ID) &&
                <th>
                    {getLangText('assignedPerson')}
                </th>
            }
            {!isSelected && (+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === LADY_WYJATKOWE_KREACJE_SHOP_ID) &&
                <th
                    name="last_label_print_date"
                    onClick={updateSortOptions}
                    className={`text-right ${getSortClass('last_label_print_date')}`}
                >
                <span name="last_label_print_date">
                    {getLangText('labelPrintDate')}
                </span>
                </th>
            }
            {!isSelected && (+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === BUTIK_MADAME_SHOP_ID) &&
                <th
                    name="inpost_label_date"
                    onClick={updateSortOptions}
                    className={`text-right ${getSortClass('inpost_label_date')}`}
                >
                <span name="inpost_label_date">
                    {getLangText('generateLabelDateLabel')}
                </span>
                </th>
            }
            {!isSelected && (+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === BUTIK_MADAME_SHOP_ID) &&
                <th
                    name="is_packed_date"
                    onClick={updateSortOptions}
                    className={`text-right ${getSortClass('is_packed_date')}`}
                >
                <span name="is_packed_date">
                    {getLangText('packedDate')}
                </span>
                </th>
            }
            {!isSelected && +userData.id === MAGIC_ROSE_SHOP_ID &&
                <th
                    name="package_closed_date"
                    onClick={updateSortOptions}
                    className={`text-right ${getSortClass('package_closed_date')}`}
                >
                <span name="package_closed_date">
                    {getLangText('packageClosedDate')}
                </span>
                </th>
            }
            {!isSelected && +userData.id === SHOP_BAZAR_SHOP_ID &&
                <th
                    name="last_print_date"
                    onClick={updateSortOptions}
                    className={`text-right ${getSortClass('last_print_date')}`}
                >
                <span name="last_print_date">
                    {getLangText('printDate')}
                </span>
                </th>
            }
        </tr>
        </thead>
    );
};

export default TableHeader;
