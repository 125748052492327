import React, {useEffect} from 'react';
import {services} from "../../../../RestServices";
import AsyncSelectField from "../../../../../src_shared/components/form/selectField/AsyncSelectField";
import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import AssignEmployeeModel from "../../../../../modules/models/order/AssignEmployeeModel";
import ClientAvatar from "../../../live/shared/Avatar";
import Loader from "../../../shared/Loader";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";

const AssignEmployee = ({defaultValues, fetchData}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        setValue
    }] = useFormLocal({
        rest: `${services.API_ORDER_EMPLOYEES}/${defaultValues.id}`,
        model: AssignEmployeeModel,
        initialAutoLoad: false,
        afterSubmit: () => {
            dispatch(showAlert())
        },
    });

    const formatOptionLabel = (value) => (
        <div className="select-custom-option flex items-center">
            {!!value.value &&
                <ClientAvatar
                    photo={value.photo}
                    fbLogo={false}
                />
            }
            <div className="name">{value.label}</div>
        </div>
    )

    useEffect(() => {
        if (defaultValues?.assigned_employee?.id) {
            setValue('employee_id', defaultValues?.assigned_employee?.id)
        }
    }, [defaultValues?.assigned_employee?.id]);

    return (
        <div className="summary-section mb-3">
            <div className="flex items-center justify-between mb-3">
                <div>
                    <div className="font-bold">
                        {getLangText('assignPersonFromTeam')}
                    </div>
                    <div className="text-[13px] leading-[17px] font-medium text-desc">
                        {getLangText('assignPersonFromTeamToThisOrder')}
                    </div>
                </div>
                <img className="w-[25px] ml-3 rounded-[3px]" src="/assets/images/svg/team.svg" alt="automate"/>
            </div>
            <form onSubmit={onSave}>
                <AsyncSelectField
                    {...getFieldProps('employee_id')}
                    className="default-size mb-0"
                    placeholder={getLangText('selectPerson')}
                    setValue={setValue}
                    // setValue={setCategoryValue}
                    formatOptionLabel={formatOptionLabel}
                    rest={services.API_ORDER_EMPLOYEES}
                    optionsParser={(options) => options?.map((i) => ({
                        value: i.id,
                        label: i.name,
                        photo: i.photo,
                    }))}
                    defaultValue={{
                        value: defaultValues?.assigned_employee?.id,
                        label: defaultValues?.assigned_employee?.name,
                        photo: defaultValues?.assigned_employee?.photo,
                    }}
                    defaultValueParser={(defaultValue) => ({
                        value: defaultValue?.value,
                        label: defaultValue?.label,
                        photo: defaultValue?.photo,
                    })}
                />
                <button
                    className={`button border-button big-size mt-2 w-100 justify-content-center ${isWaiting ? 'loading-button' : ''}`}
                    type="submit"
                >
                    {isWaiting && <Loader isLoading/>}
                    {getLangText('saveButton')}
                </button>
            </form>
        </div>
    );
};

export default AssignEmployee;