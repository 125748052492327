export const ORDER_PAYMENT_PICKUP = 'pickup';
export const ORDER_PAYMENT_TRANSFER = 'transfer';
export const ORDER_PAYMENT_BLUEMEDIA = 'bluemedia';
export const ORDER_PAYMENT_BLIK = 'blik';
export const ORDER_PAYMENT_SHOP_PICKUP = 'shop_pickup';
export const ORDER_PAYMENT_PAYPAL = 'paypal';
export const ORDER_PAYMENT_STRIPE_CARD = 'stripe_card';
export const ORDER_PAYMENT_MB_WAY = 'lusopay_mbway';
export const ORDER_PAYMENT_LUSOPAY_MULTIBANCO = 'lusopay_multibanco';
export const ORDER_PAYMENT_BM_TRANSFER = 'bm_transfer';
export const ORDER_PAYMENT_MOBILEPAY = 'mobilepay';
export const ORDER_PAYMENT_PAYPAL_AUTOMATIC = 'paypal_automatic';
export const ORDER_PAYMENT_BANCONTACT = 'stripe_bancontact';

export const ORDER_PAYMENT_STATUES = [
    {
        value: ORDER_PAYMENT_BLUEMEDIA, label: 'paymentMethodDefaultOnlineTransferLabel',
    },
    {
        value: ORDER_PAYMENT_BLIK, label: 'paymentMethodBlikLabel',
    },
    {
        value: ORDER_PAYMENT_TRANSFER, label: 'paymentMethodTransferLabel',
    },
    {
        value: ORDER_PAYMENT_BM_TRANSFER, label: 'paymentMethodTransferLabel',
    },
    {
        value: ORDER_PAYMENT_PICKUP, label: 'paymentMethodCashOnDeliveryLabel',
    },
    {
        value: ORDER_PAYMENT_SHOP_PICKUP, label: 'paymentMethodCashInShopLabel',
    },
    {
        value: ORDER_PAYMENT_PAYPAL, label: 'paymentMethodPaypalLabel',
    },
    {
        value: ORDER_PAYMENT_PAYPAL_AUTOMATIC, label: 'paymentMethodPaypalLabel',
    },
    {
        value: ORDER_PAYMENT_STRIPE_CARD, label: 'cardPaymentLabel',
    },
    {
        value: ORDER_PAYMENT_MB_WAY, label: 'mbwayLabel',
    },
    {
        value: ORDER_PAYMENT_MOBILEPAY, label: 'paymentMethodMobilePayLabel',
    },
    {
        value: ORDER_PAYMENT_LUSOPAY_MULTIBANCO, label: 'multibanco',
    },
    {
        value: ORDER_PAYMENT_BANCONTACT, label: 'bancontact',
    },
]

export const findOrderPaymentStatusById = (id) => ORDER_PAYMENT_STATUES.find((status) => status.value === id) || null;

export const getCorrectOrderPaymentName = (id) => {
    if (findOrderPaymentStatusById(id)?.value === ORDER_PAYMENT_BM_TRANSFER) {
        return 'paymentMethodAutomationTransferLabel';
    }
    if (findOrderPaymentStatusById(id)?.value === ORDER_PAYMENT_PAYPAL_AUTOMATIC) {
        return 'paymentMethodPaypalAutomaticLabel';
    }
    return findOrderPaymentStatusById(id)?.label;
}
