import React, {useState} from 'react';
import Loader from "../../../../messenger/loader/Loader";
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {batch, useDispatch, useSelector} from "react-redux";
import {useRemoveModal} from "../../../../shared/hooks/useRemoveModal";
import {services} from "../../../../../RestServices";
import api from "../../../../../../services/axios/axios";
import {selmoUrl} from "../../../../../../src_shared/api/api";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import {useDownloadFile} from "../../../../../../src_shared/hooks/useDownloadFile";
import {usePrintFile} from "../../../../../../src_shared/hooks/usePrintFile";
import ConfirmModal from "../../../list/components/export/ConfirmModal";
import EditOrderFormActions from "../../form/FormActions";
import {getGETParamsUrl} from "../../../../../../src_shared/list/ListActions";
import StagesHistory from "./stages/StagesHistory";
import {
    BUTIK_MADAME_PLUS_SIZE_SHOP_ID,
    CARMELA2_SHOP_ID, CARMELA_KIELCE_SHOP_ID,
    CARMELA_SHOP_ID,
    SHOP_BAZAR_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID,
    TIM_SHOP_ID
} from "../../../../../../src_shared/enums/TrustedShopsIds";
import PrinterSelect from "./PrinterSelect";

const AfterCreatedButtons = ({data, stage = null}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const [isWaiting, setIsWaiting] = useState(false);
    const [isWaitingForFiscalPrint, setIsWaitingForFiscalPrint] = useState(false);
    const {userData} = useSelector((state) => state.session);
    const [selectedPrinter, setSelectedPrinter] = useState(null);

    const [inputValues, setInputValues] = useState({
        is_active_email: false,
        is_active_messenger: false,
    })

    const loadData = () => dispatch(EditOrderFormActions.loadData(data.shop_order_id))

    const [modalOpen, setModalOpen, waiting, remove] = useRemoveModal(`${services.API_FAKTUROWNIA_ORDER_INVOICE}/${data.id}${getGETParamsUrl({order_stage: stage})}`, loadData)

    const onFiscalPrint = async () => {
        setIsWaitingForFiscalPrint(true);
        try {
            await api.post(`${selmoUrl}/${services.API_ORDER_FISCAL_PRINT}/${data.id}${getGETParamsUrl({order_stage: stage})}`, {
                invoice_id: data.shippingData.fakturownia_invoice,
                printer: selectedPrinter,
            })
            batch(() => {
                dispatch(showAlert('successfulInvoiceGeneratedLabel'))
                dispatch(EditOrderFormActions.loadData(data.shop_order_id))
            })
        } catch (e) {
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
            console.error('Error submitEditForm')
        } finally {
            setIsWaitingForFiscalPrint(false);
        }
    }

    const sendDocument = async () => {
        setIsWaiting(true);
        try {
            await api.post(`${selmoUrl}/${services.API_ORDER_SEND_INVOICE}/${data.id}${getGETParamsUrl({order_stage: stage})}`, {
                is_active_email: inputValues.is_active_email,
                is_active_messenger: inputValues.is_active_messenger,
            })
            batch(() => {
                dispatch(showAlert('successfulInvoiceSentLabel'))
                dispatch(EditOrderFormActions.loadData(data.shop_order_id))
            })
        } catch (e) {
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
            console.error('Error submitEditForm')
        } finally {
            setIsWaiting(false);
        }
    }

    const emailSent = (!!data.invoices && !!data.invoices[+stage]) ? data.invoices[+stage]?.fakturownia_invoice_email : data.shippingData.fakturownia_invoice_email;
    const messengerSent = (!!data.invoices && !!data.invoices[+stage]) ? data.invoices[+stage]?.fakturownia_invoice_messenger : data.shippingData.fakturownia_invoice_messenger;

    const {download, waiting: waitingForInvoice} = useDownloadFile({
        rest: `${services.API_INTEGRATIONS_FAKTUROWNIA}/${data.id}${getGETParamsUrl({order_stage: stage})}`,
        fileName: 'Fakturownia',
    })

    const {print, waiting: waitingForPrint} = usePrintFile({
        rest: `${services.API_INTEGRATIONS_FAKTUROWNIA}/${data.id}${getGETParamsUrl({order_stage: stage})}`,
    })

    return (
        <>
            <div
                style={{minHeight: 'auto'}}
                className="loader-parent"
            >
                {(waitingForInvoice || waitingForPrint) ?
                    <div
                        style={{height: 37, background: 'transparent'}}
                        className={`loader-container small-loader w-auto static-loader flex items-center justify-center`}>
                        <div
                            style={{transform: 'none'}}
                            className="circle-loader">
                            <span className="circle"/>
                        </div>
                    </div> :
                    <div className="tripple-buttons">
                        <div>
                            <button
                                onClick={print}
                                type="button"
                                className="button border-button big-width position-relative"
                            >
                                {getLangText('printButton')}
                                <i className="icon-print"/>
                            </button>
                        </div>
                        <div>
                            <button
                                type="button"
                                onClick={download}
                                className="button border-button big-width"
                            >
                                {getLangText('previewButton')}
                                <i className="icon-eye"/>
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={() => setModalOpen(true)}
                                type="button"
                                className="button border-button big-width"
                            >
                                {getLangText('removeButton')}
                                <i className="icon-bin"/>
                            </button>
                        </div>
                    </div>
                }
            </div>

            {!!+data.shippingData.issue_receipts &&
                <div className="tooltip-parent">
                    {!!data.shippingData.fakturownia_fiscal_print_date &&
                        <div className="tooltip-content">
                            {getLangText('createdFiscalPrintLabel')}
                        </div>
                    }
                    {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === SHOP_BAZAR_SHOP_ID || +userData.id === TIM_SHOP_ID || +userData.id === CARMELA_SHOP_ID || +userData.id === CARMELA2_SHOP_ID || +userData.id === BUTIK_MADAME_PLUS_SIZE_SHOP_ID || +userData.id === CARMELA_KIELCE_SHOP_ID) &&
                       <PrinterSelect
                           selectedPrinter={selectedPrinter}
                           setSelectedPrinter={setSelectedPrinter}
                           userData={userData}
                       />
                    }
                    <button
                        onClick={onFiscalPrint}
                        type="button"
                        className="button border-button big-width position-relative w-100 mt-2"
                        disabled={!!data.shippingData.fakturownia_fiscal_print_date}
                    >
                        {isWaitingForFiscalPrint && <Loader/>}
                        {getLangText('fiscalPrintLabel')}
                        <i className="icon-print"/>
                    </button>
                </div>
            }
            <hr/>
            <div className="d-flex align-items-center mb-3">
                <h3 className="flex-grow-1 mb-0">{getLangText('sendToClientLabel')}</h3>
                <div className="tooltip-parent d-flex">
                    {emailSent &&
                        <div className="tooltip-content">
                            {getLangText('sentDocumentLabel')}
                        </div>
                    }
                    <SwitchField
                        className={`switch-form inline-label mr-2 mb-0 ${emailSent ? 'disabled' : ''}`}
                        label="E-mail"
                        name="is_active_email"
                        setValue={(name, value) => setInputValues({
                            ...inputValues,
                            [name]: value
                        })}
                        value={emailSent ? false : inputValues.is_active_email}
                        disabled={emailSent}
                    />
                </div>
                <div className="tooltip-parent d-flex">
                    {messengerSent &&
                        <div className="tooltip-content">
                            {getLangText('sentDocumentLabel')}
                        </div>
                    }
                    <SwitchField
                        className={`switch-form inline-label mb-0 ${messengerSent ? 'disabled' : ''}`}
                        label={data.client.social_type === 'facebook' ? 'Messenger' : 'Instagram'}
                        name="is_active_messenger"
                        setValue={(name, value) => setInputValues({
                            ...inputValues,
                            [name]: value
                        })}
                        value={messengerSent ? false : inputValues.is_active_messenger}
                        disabled={messengerSent}
                    />
                </div>
            </div>
            <div className="tooltip-parent">
                {!+inputValues.is_active_email && !+inputValues.is_active_messenger &&
                    <div className="tooltip-content">
                        {getLangText('selectEmailOrMessengerLabel')}
                    </div>
                }
                <button
                    onClick={sendDocument}
                    type="button"
                    className="button border-button big-width w-100 position-relative"
                    disabled={!+inputValues.is_active_email && !+inputValues.is_active_messenger}
                >
                    {isWaiting && <Loader/>}
                    {getLangText('sendDocumentLabel')}
                </button>
            </div>
            {!!+stage &&
                <StagesHistory data={data.invoices[+stage]}/>
            }
            {modalOpen &&
                <ConfirmModal
                    title={getLangText('askForRemoveDocumentLabel')}
                    SubTitle={() => <>{getLangText('actionCannotBeUnDoneLabel')}</>}
                    saveButton={getLangText('removeButton')}
                    show={modalOpen}
                    isWaiting={waiting}
                    onSubmit={remove}
                    hide={() => setModalOpen(false)}
                    submitButtonClass="danger"
                />
            }
        </>
    );
};

export default AfterCreatedButtons;
