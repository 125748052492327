import React, {useState} from 'react';
import Profile from "./Profile";
import useWindowDimensions from "../../messenger/hooks/useWindowDimensions/useWindowDimensions";
import FunctionalityBoxes from "../../../../src_shared/components/functionalityBoxes/FunctionalityBoxes";
import HelpDropdown from "./HelpDropdown";
import FilesDropdown from "./files/filesDropdown/FilesDropdown";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import BalanceModal from "../../orders/details/selmoPaczka/components/shared/BalanceModal";
import {isMobile} from "../helpers/isMobile";
import SearchModalButton from "./SearchModalButton";
import BlackWeekButton from "../components/BlackWeekButton/BlackWeekButton";

const Header = ({userData, inSettings = false}) => {
    const {width} = useWindowDimensions();
    const [showBalanceModal, setShowBalanceModal] = useState(false);
    const mobile = isMobile();

    return (
        <div className="header-bar justify-end">
            {width > 991 &&
                <>
                    <FunctionalityBoxes/>
                    {inSettings &&
                        <SearchModalButton />
                    }
                    <BlackWeekButton />
                    {!!+userData.sp_active &&
                        <button
                            type="button"
                            onClick={() => setShowBalanceModal(true)}
                            className="p-2 mr-2 flex items-center text-xs font-medium bg-[#E5E7EB] text-[#4B5563] rounded-[16px] hover:text-[#000] transition-all duration-200 hover:no-underline"
                        >
                            <i className="icon-balance mr-1"/>
                            Saldo:&nbsp;<strong>{userData.sp_balance} zł</strong>
                        </button>
                    }
                    {/*<VacationButton/>*/}
                    {!!+userData.depot_system && mobile &&
                        <Link className="mr-3 flex hover:no-underline items-center"
                              to={RouterPaths.StorageScanner}>
                            <i className="icon-qr-code"/>
                        </Link>}
                    {!!+userData.depot_system &&
                        <FilesDropdown/>
                    }
                    <HelpDropdown/>
                    {/*<NotificationsPanelButton />*/}
                    <Profile userData={userData}/>
                    {showBalanceModal &&
                        <BalanceModal
                            show={showBalanceModal}
                            hideModal={() => setShowBalanceModal(false)}
                        />
                    }
                </>
            }
        </div>
    );
};

export default Header;
