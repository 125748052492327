import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Loader from "../../loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import Message from "./Message";
import {getConversation, setConversationPage, updateNextParameter} from "../ChatBoxActions";
import {useDispatch, useSelector} from "react-redux";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useInView} from "react-hook-inview";

const MessagesList = ({setStatusVisible, statusVisible, messageScrollRef, lastId}) => {

	const dispatch = useDispatch();
	const {conversationId} = useParams();
	const {getLangText} = useLang();
	const [ref, inView] = useInView();

	const controller = new AbortController();
	const signal = controller.signal;

	const [touchedScroll, setTouchedScroll] = useState(false);

	const {items, isLoading, noResults, isError, activeConversation} = useSelector((state) => state.messenger.chatBox)
	const {displayType} = useSelector((state) => state.messenger.chatList)

	const onNextPage = () => {
		dispatch(setConversationPage())
		dispatch(getConversation(conversationId, false, signal))
	}

	useEffect(() => {
		if (conversationId) {
			dispatch(updateNextParameter(null))
			dispatch(getConversation(conversationId, true, signal))

			return () => {
				controller.abort();
			};
		}
	}, [conversationId]);

	useEffect(() => {
		if (inView && !noResults && !touchedScroll) {
			onNextPage()
		}
		return () => {
			if (inView && !noResults && !touchedScroll) {
				controller.abort();
			}
		};
	}, [inView, !noResults])


	return (
		<>
			<div ref={messageScrollRef} className={`messenger-content loader-parent ${isError ? 'with-error' : ''}`}
				 id="scrollableDiv">
				{isError ?
					<div>{getLangText('sthWentWrongWarning')}</div> :
					isLoading ?
						<Loader/> :
						<InfiniteScroll
							dataLength={items.length}
							next={onNextPage}
							style={{
								display: 'flex',
								flexDirection: 'column-reverse'
							}}
							inverse={true}
							hasMore={!noResults}
							loader={!noResults &&
								<div className="scroll-list-loader">{getLangText('loadingLabel')}</div>}
							scrollableTarget="scrollableDiv"
							onScroll={() => setTouchedScroll(true)}
						>
							<div style={{float: "left", clear: "both"}}
								 ref={messageScrollRef}
							>
							</div>
							{items.map((item, index) => (
								<Message
									pageId={activeConversation.page_id}
									avatar={activeConversation.user_avatar}
									conversationId={conversationId}
									key={item.id}
									index={index}
									item={item}
									lastId={lastId}
									setStatusVisible={setStatusVisible}
									statusVisible={statusVisible}
									items={items}
									displayType={displayType}
								/>
							))}
							<div
								style={{height: '20px'}}
								className="for-intersection" ref={ref}
							/>
						</InfiniteScroll>
				}
			</div>
		</>
	);
};

export default MessagesList;
