import React, {useEffect, useMemo, useState} from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {useSelector} from "react-redux";
import {getFromLS, saveToLS} from "../../../../../src_shared/helpers/LS";
import useLang from "../../../../../src_shared/hooks/useLang";
import {getCorrectPriceCurrency} from "../../../blackFriday/BlackFriday";
import {convertToUserTimeZone} from "../../helpers/dateHelpers";
import moment from "moment";

const RedTopBar = () => {
    const {userData} = useSelector((state) => state.session);
    const origin = userData.origin
    const {getLangText} = useLang();
    const price = useMemo(() => `${getCorrectPriceCurrency(origin).price} ${getCorrectPriceCurrency(origin).shortcut}`, [origin])
    const endDate = convertToUserTimeZone(moment('02.12.2024 23:59:59', 'DD.MM.YYYY HH:mm:ss').toDate()).toDate()
    const todayDate = convertToUserTimeZone(moment(new Date(), 'DD.MM.YYYY HH:mm:ss').toDate()).toDate()
    const expired = endDate <= todayDate

    const isHidden = getFromLS('blackWeekHidden');
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        saveToLS('blackWeekHidden', hidden);
    }, [hidden])

    useEffect(() => {
        if (isHidden) {
            setHidden(true)
        }
    }, [isHidden])

    if (!!+userData.selmo_pro_active || !userData.user_info.premium_date) {
        return null;
    }

    if (hidden || expired) return null;

    // if (origin !== SHOP_ORIGIN_PL) return null;

    // if (!countDownVisible) return null

    return (
        <Link
            to={RouterPaths.BlackFriday}
            className="relative selmo-app-bar w-full bg-gradient-to-r from-[#000] to-[#003CFF] sm:flex-wrap flex text-[#fff] items-center font-semibold text-xs sm:justify-center min-h-[40px] py-1 px-2 sm:px-6 hover:text-[#fff] hover:no-underline group">
            <div>
                {getLangText({key: 'blackWekekTryPromoAndSaveMoney', data: [price]})}
            </div>
            <div
                className={`bg-[#fff] relative button white px-[8px] py-[3px] font-bold rounded-[5px] whitespace-nowrap ml-2 text-[#101827] group-hover:text-[#101827] transition-all`}
            >
                {getLangText('findOutMoreButton')}
            </div>
            <img className="h-[40px] ml-3 md:block hidden" src="/assets/images/bw-bar_1.png" alt=""/>
            <button
                type="button"
                className="sm:ml-auto sm:absolute right-4 sm:top-1/2 sm:-translate-y-1/2 ml-1.5"
                onClick={() => setHidden(true)}
            >
                <i className="icon-cross"/>
            </button>
        </Link>
    );
};

export default RedTopBar;
