import React, {useEffect, useState} from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {services} from "../../../../../../RestServices";
import Modal from "react-bootstrap/Modal";
import SearchInput from "../../../../../shared/components/form/SearchInput";
import Loader from "../../../../../shared/Loader";
import Product from "./Product";
import useScrollList from "../../../../../../../src_shared/hooks/useScrollData";
import InfiniteScroll from "react-infinite-scroll-component";

const SelectProductModal = ({modalVisible, hideModal, updateValues, values, isWaiting, onModalSave}) => {

    const {getLangText} = useLang();

    const [selectedParams, setSelectedParams] = useState({
        superSearch: '',
    })

    const {
        getNewPage,
        hasMore,
        isNewPageLoading,
        isLoading,
        data,
        page,
        setPage,
        fetchData,
    } = useScrollList({
        simpleArray: true,
        params: selectedParams,
        initialAutoLoad: false,
        rest: services.API_VIDEO_CATEGORY_PRODUCTS,
    });

    const search = {
        updateSearchText: (value) => setSelectedParams((prevState) => ({...prevState, superSearch: value})),
        callSearch: fetchData,
        searchText: selectedParams.superSearch,
    }

    useEffect(() => {
        if (page === 1) {
            fetchData()
        }
    }, [page])

    useEffect(() => {
        // setPage(1)
    }, [selectedParams.superSearch])

    const setActiveProduct = (activeProduct, active_collection_id = null) => updateValues({
        active_collection_id,
        selectedProduct: activeProduct,
    });

    return (
        <Modal
            dialogClassName="default-modal"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header className="d-block">
                <div className="flex items-center">
                    <Modal.Title>
                        {getLangText('changeDisplayedProduct')}
                        <div className="sub-title">{getLangText('selectAndPresent')}</div>
                    </Modal.Title>
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button text-[9px] text-[#6B7280] ml-auto"
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body className="pt-3 pb-5 assign-products-body">
                <SearchInput
                    className="mb-2"
                    placeholder={getLangText('searchPlaceholder')}
                    search={search}
                />
                <div className="loader-parent">
                    <Loader isLoading={isLoading}/>
                    {!data?.length ?
                        <div className="flex items-center justify-center mt-4">
                            <div className="text-center max-w-[200px]">
                                <div className="font-bold">{getLangText('noProductsLabel')}</div>
                                <div className="text-desc font-medium">
                                    <div>{getLangText('changeSearchOrAddProductsLabel')}</div>
                                </div>
                            </div>
                        </div> :
                        <div className="data-boxes-wrapper small-padd products-boxes">
                            <div
                                className="max-h-[500px] overflow-y-auto"
                                id="scrollableDiv"
                                style={{flex: 1}}
                            >
                                <InfiniteScroll
                                    dataLength={data.length}
                                    next={getNewPage}
                                    loader={isNewPageLoading && <Loader isLoading={true} className="static-loader"/>}
                                    hasMore={hasMore}
                                    scrollableTarget="scrollableDiv"
                                    className=""
                                >
                                    {data.map((item) => (
                                        <Product
                                            key={item.id}
                                            item={item}
                                            activeProduct={values.selectedProduct}
                                            setActiveProduct={setActiveProduct}
                                            values={values}
                                        />
                                    ))}
                                </InfiniteScroll>
                            </div>
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    onClick={onModalSave}
                    type="submit"
                    className={`button primary px-4 ${isWaiting ? 'loading-button' : ''} ${!values.selectedProduct ? 'not-active' : ''}`}
                    disabled={!values.selectedProduct}
                >
                    {isWaiting && <Loader isLoading={true}/>}
                    {getLangText('present')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default SelectProductModal;
