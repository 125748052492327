import {ORDER_INVOICE_STATUES} from "./OrdersInvoiceStatuses";

const YES_NO_TYPES = [
    {value: 'all', label: 'allLabel'},
    {id: '1', value: '1', label: 'yesButton'},
    {id: '0', value: '0', label: 'noButton'},
]

export const findYesNoTypesById = (id) => YES_NO_TYPES.find((status) => status.value === id);

export default YES_NO_TYPES;
