import React, {useState} from 'react';
import useList from "../../../../src_shared/list/useList";
import ListActions from "./ListActions";
import {getStateRoot as getFiltersPanelStateRoot} from './filtersPanel/form/formReducer';
import {getStateRoot} from "./listReducer";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer";
import Pagination from "../../shared/components/pagination/Pagination";
import SearchInput from "../../shared/components/form/SearchInput";
import DateRangePicker from "../../shared/components/form/DateRangePicker";
import FiltersActions from "../filters/FiltersActions";
import EmptyList from "../../shared/EmptyList";
import useWindowDimensions from "../../messenger/hooks/useWindowDimensions/useWindowDimensions";
import {batch, useDispatch, useSelector,} from "react-redux";
import FormActions from "../form/FormActions";
import TableBody from "./components/table/TableBody";
import TableHeader from "./components/table/TableHeader";
import useTableColumn from "./components/table/useTableColumn";
import Export from "./components/export/Export";
import {RouterPaths} from "../../../routes/RouterPath";
import StatusFilter from "../../../../src_shared/components/filters/statusFilter/StatusFilter";
import {ORDER_STATUES} from "../../shared/enums/OrderStatuses";
import ColumnVisibilityFilter
    from "../../../../src_shared/components/filters/columnVisibilityFilter/ColumnVisibilityFilter";
import {ORDER_TABLE_COLUMN} from "./components/table/OrderTableColumnEnums";
import useTableScrollClasses from "../../../../src_shared/hooks/useTableScrollClassses";
import {createSelector} from "reselect";
import useCheckboxMultiselect from "../../../../src_shared/list/checkboxMultiselect/useCheckboxMultiselect";
import FiltersPanel from "./filtersPanel/form/FiltersPanel";
import OrderListFiltersPanelActions from "./filtersPanel/form/FormActions";
import {ORDER_PACKED_FILTER_ALL} from "../../shared/enums/PackedStatuses";
import {ORDER_PRODUCTS_PAID_FILTER_ALL} from "../../shared/enums/PaidStatuses";
import OrdersWrapper from "../Page";
import {ORDER_LABEL_ALL} from "../../shared/enums/OrdersLabelStatuses";
import {ORDER_INVOICE_ALL} from "../../shared/enums/OrdersInvoiceStatuses";
import SelectedFilters from "./components/selectedFilters/SelectedFilters";
import useLang from "../../../../src_shared/hooks/useLang";
import {ALL_ORDERS_VIEW} from "../../shared/enums/OrderViewTypes";
import FirstStepPage from "../../../../src_shared/components/ui/FirstStepPage/FirstStepPage";
import ComponentLoader from "../../shared/components/componentLoader/ComponentLoader";
import {FIRST_PAGE_ORDERS} from "../../shared/enums/TutorialVideoStatsTypes";

export const getCorrectCountry = (country) => country && country?.toLowerCase() !== 'polska' && `${country?.trim()}`;

export const firstPageBenefits = [
    {id: 0, title: 'manageOrdersLabel', desc: 'manageOrdersSubLabel'},
    {id: 1, title: 'monitorStatusesLabel', desc: 'monitorStatusesSubLabel'},
    {id: 1, title: 'generateLabelsInvoicesInBulkLabel', desc: 'generateLabelsInvoicesInBulkSubLabel'},
]

const OrdersList = () => {
    const dispatch = useDispatch();
    const [isFiltersVisible, setFiltersVisible] = useState(false);
    const {width} = useWindowDimensions();
    const {tableColumn} = useTableColumn(ORDER_TABLE_COLUMN, 'tableColumn', getFiltersStateRoot);
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    const {first_step_orders} = useSelector((state) => state.session.userData);

    const {selectedItems} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const {
        statuses,
        payments,
        shipping,
        employees,
        tag,
        isPaid,
        isPacked,
        label,
        invoice,
        discounts,
        country,
        mr_automate_paused,
        priceFrom,
        priceTo,
        productsFrom,
        productsTo,
        productType,
        nip,
        printed,
        label_printed
    } = useSelector(createSelector(getFiltersStateRoot, (stateRoot) => stateRoot.data));

    const [panelVisible] = useSelector(createSelector(getFiltersPanelStateRoot, (stateRoot) => [
        stateRoot.modalVisible,
    ]));

    const isFiltersActive = !!statuses.length
        || !!payments.length
        || !!shipping.length
        || !!employees.length
        || !!tag.length
        || !!discounts.length
        || !!country?.length
        || isPaid !== ORDER_PRODUCTS_PAID_FILTER_ALL
        || isPacked !== ORDER_PACKED_FILTER_ALL
        || label !== ORDER_LABEL_ALL
        || invoice !== ORDER_INVOICE_ALL
        || (!!+userData.selmo_pro_active && mr_automate_paused !== 'all')
        || priceFrom
        || priceTo
        || productsFrom
        || productsTo
        || productType
        || nip !== 'all'
        || printed !== 'all'
        || label_printed !== 'all'

    const beforeDestroy = () => {
        const nextPathName = window.location.pathname;
        const preparedPathName = nextPathName.replace(/(\d+)/, ':id');

        if (preparedPathName !== RouterPaths.OrderForm) {
            reset();
        }
    };

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage,
        },
        list: {items, isWaiting, reset, loadData, isFirstStepPage},
        search,
        sort: {updateSortOptions, getSortClass},
    } = useList({
        ListActions,
        FiltersActions,
        getStateRoot,
        beforeDestroy,
    });

    const {
        selectAllItems,
        toggleSelection,
        checkIfSomeExist,
        isSelected,
    } = useCheckboxMultiselect(ListActions.selectedItems, selectedItems, items);

    const {tableRef, tableResponsiveRef} = useTableScrollClasses(tableColumn, isWaiting)

    const showAddOrderModal = () => dispatch(FormActions.modal.showModal());

    const toggleFiltersPanelVisibility = () => {
        if (panelVisible) {
            dispatch(OrderListFiltersPanelActions.modal.hideModal());
            return;
        }
        dispatch(OrderListFiltersPanelActions.modal.showModal());
    }

    const showAllOrders = () => {
        batch(() => {
            dispatch(FiltersActions.data.updateValues({
                fromDate: null,
                toDate: null,
                viewType: ALL_ORDERS_VIEW,
            }))
            dispatch(ListActions.loadData())
        })
    }

    const SetAllOrdersViewButton = () => (
        <button
            onClick={showAllOrders}
            type="button"
            className="button primary small-size mt-3"
        >
            {getLangText('selectFullDateRangeLabel')}
        </button>
    )

    if (isWaiting && !!+first_step_orders) return <ComponentLoader/>

    return (
        isFirstStepPage ?
            <FirstStepPage
                title="asideOrdersLabel"
                shortDesc="manageYourCustomersOrdersLabel"
                icon="orders-icon"
                fullDesc="manageYourCustomersOrdersFullLabel"
                buttonClick={showAddOrderModal}
                headerButtonTitle="addOrderLabel"
                mainButtonTitle="createFirstOderLabel"
                previewSrc="/assets/images/furgonetka02.jpg"
                benefits={firstPageBenefits}
                videoStatsName={FIRST_PAGE_ORDERS}
            /> :
            <OrdersWrapper
                totalItems={totalItems}
                setFiltersVisible={setFiltersVisible}
                isFiltersVisible={isFiltersVisible}
                width={width}
                showAddOrderModal={showAddOrderModal}
            >
                <div className="table-list-wrapper">
                    <div className="default-shadow-box pb-0 pt-sm-3">
                        <div className="table-header mb-sm-2 mb-0">
                            {(width > 767 || isFiltersVisible) &&
                                <div className="filters-part mt-0">
                                    <StatusFilter
                                        ListActions={ListActions}
                                        FiltersActions={FiltersActions}
                                        getFiltersStateRoot={getFiltersStateRoot}
                                        options={ORDER_STATUES}
                                        name="statuses"
                                        selectAllName="filtersSelectAll"
                                        defaultSelectedLabel={getLangText('statusLabel')}
                                    />
                                    <button
                                        type="button"
                                        className={`button border-button ml-md-2 filters-open-button ${isFiltersActive ? 'active-filters' : ''}`}
                                        onClick={toggleFiltersPanelVisibility}
                                    >
                                        {getLangText('filterButton')}
                                        <i className='icon-filter ml-1'/>
                                    </button>
                                    <Export/>
                                    <ColumnVisibilityFilter
                                        ListActions={ListActions}
                                        FiltersActions={FiltersActions}
                                        getFiltersStateRoot={getFiltersStateRoot}
                                        options={ORDER_TABLE_COLUMN}
                                        name="tableColumn"
                                        selectAllName="tableColumnSelectAll"
                                    />
                                    <SearchInput
                                        placeholder={getLangText('searchPlaceholder')}
                                        search={search}
                                    />
                                    <DateRangePicker
                                        actions={ListActions}
                                        getFiltersStateRoot={getFiltersStateRoot}
                                        FiltersActions={FiltersActions}
                                    />
                                </div>
                            }
                            {isFiltersActive &&
                                <SelectedFilters
                                    statuses={statuses}
                                    payments={payments}
                                    shipping={shipping}
                                    tag={tag}
                                    isPaid={isPaid}
                                    isPacked={isPacked}
                                    label={label}
                                    invoice={invoice}
                                    loadData={loadData}
                                    discounts={discounts}
                                    country={country}
                                    mr_automate_paused={mr_automate_paused}
                                    priceFrom={priceFrom}
                                    priceTo={priceTo}
                                    productsTo={productsTo}
                                    productsFrom={productsFrom}
                                    productType={productType}
                                    nip={nip}
                                    printed={printed}
                                    label_printed={label_printed}
                                    employees={employees}
                                />
                            }
                        </div>
                        <EmptyList
                            items={items}
                            isLoading={isWaiting}
                            className="with-padd"
                            imgSrc="/assets/images/empty/orders-empty.svg"
                            title={getLangText('emptyHereLabel')}
                            text={getLangText('changeSearchOrAddOrderLabel')}
                            ExtraComponent={SetAllOrdersViewButton}
                        >
                            <div className="table-wrapper loader-parent">
                                <div
                                    ref={tableResponsiveRef}
                                    className="table-responsive"
                                >
                                    <table
                                        ref={tableRef}
                                        className={`table default-list-table live-table`}
                                    >
                                        <TableHeader
                                            selectAllItems={selectAllItems}
                                            checkIfSomeExist={checkIfSomeExist}
                                            updateSortOptions={updateSortOptions}
                                            getSortClass={getSortClass}
                                            selectedItems={selectedItems}
                                        />
                                        <TableBody
                                            toggleSelection={toggleSelection}
                                            isSelected={isSelected}
                                            items={items}
                                        />
                                    </table>
                                </div>
                            </div>
                        </EmptyList>
                    </div>
                    <Pagination
                        getLangText={getLangText}
                        pages={getPagination()}
                        items={items}
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        onPrevPage={prevPage}
                        onChangePage={changePage}
                        onNextPage={nextPage}
                        onChangeItemsPerPage={updateItemsPerPage}
                        classes={{
                            prevIcon: 'icon-chevron left',
                            nextIcon: 'icon-chevron right',
                        }}
                        showItemsPerPageSelect
                    />
                    {panelVisible &&
                        <FiltersPanel
                            FiltersActions={FiltersActions}
                            ListActions={ListActions}
                        />
                    }
                </div>
            </OrdersWrapper>
    );
};

export default OrdersList;
