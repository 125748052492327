import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../../app/shared/Loader";
import Wrapper from "../Wrapper";
import ConfirmModal from "../../../../app/orders/list/components/export/ConfirmModal";
import Model from "../../../../../src_shared/modules/model/Model";
import {useDispatch} from "react-redux";
import {showAlert} from "../../../../app/shared/components/alert/AlertActions";
import ConnectModal from "./ConnectModal";
import useSubmitData from "../../../../../src_shared/hooks/useSubmitData";
import SettingsShopifyActiveModel from "../../../../../modules/models/settings/SettingsShopifyActiveModel";

const ShopifyConfiguration = () => {
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(false);
    const [showConnectModal, setShowConnectModal] = useState(false);
    const dispatch = useDispatch();
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

    const [{isLoading, values, fetchData, getFieldProps, onSave, isWaiting: isWaitingForSubmit}] = useFormLocal({
        rest: services.API_SETTINGS_SHOPIFY,
        model: SettingsShopifyActiveModel,
        method: 'put',
        afterSubmit: (response) => {
            if (response.url) {
                window.location.href = response.url
            } else {
                dispatch(showAlert())
            }
        }
    });

    const [{onSave: disconnect, isWaiting}] = useSubmitData({
        rest: services.API_SETTINGS_SHOPIFY_DISCONNECT,
        afterSubmit: async () => {
            await fetchData();
        }
    });

    const [{onSave: syncProducts, isWaiting: waitingForProducts}] = useFormLocal({
        rest: services.API_SETTINGS_SYNC_SHOPIFY,
        initialAutoLoad: false,
        model: new Model(),
        method: 'get',
        afterSubmit: () => {
            setShowModal(false)
            dispatch(showAlert('syncProductsSuccess', 'productsWillShowInFewMinutes'))
        }
    });

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
                    </li>
                    <li className="active">
                        Shopify
                    </li>
                </ul>
            </div>
            <Wrapper
                isConnected={!!+values.is_connected}
                setShowModal={setShowModal}
            >
                <div className="loader-parent">
                    <Loader isLoading={isLoading}/>
                    <div className="settings-form pt-4">
                        <div className="font-bold text-sm">Shopify</div>
                        <div
                            className="text-desc text-sm mb-3 font-medium">{getLangText('connectYourAccountWithShopify')}</div>
                        {!!+values.is_connected &&
                            <div>
                                <div className="flex items-center flex-wrap mb-3">
                                    <div
                                        style={{maxWidth: '100%', width: 'auto'}}
                                        className="form-info-box small-line-height align-items-center w-full green grow">
                                        <div>
                                            <i className="icon-tick-c"/>
                                        </div>
                                        <div className="title">
                                            {getLangText('accountHasBeenConnectedLabel')}
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => setIsConfirmModalVisible(true)}
                                        className={`button border-button sm:w-auto w-full sm:mt-0 mt-2.5 ml-sm-3`}
                                        type="button"
                                    >
                                        {getLangText('disconnect')}
                                    </button>
                                </div>
                                <SwitchField
                                    {...getFieldProps('reserve_items')}
                                    className="switch-form align-items-center inline-label big-label light-version mb-0 mt-0 p-3 border border-gray-200 rounded-md w-full"
                                    label={getLangText('reserveProductsInOrder')}
                                    id="reserve_items"
                                />
                                <div className="mt-3 text-right">
                                    <button
                                        onClick={onSave}
                                        type="button"
                                        className={`button primary ml-auto ${isWaitingForSubmit ? 'loading-button' : ''}`}
                                    >
                                        {isWaitingForSubmit && <Loader isLoading/>}
                                        {getLangText('saveButton')}
                                    </button>
                                </div>
                            </div>
                        }
                        {/*<div className="steps-wrapper">*/}
                        {/*    <div className="step">*/}
                        {/*        <div className="step-header align-items-start">*/}
                        {/*            <div className="title d-block flex-grow-1">*/}
                        {/*                <Field*/}
                        {/*                    {...getFieldProps('shop')}*/}
                        {/*                    disabled={!!+values.is_connected}*/}
                        {/*                    label={getLangText('shopUrl')}*/}
                        {/*                />*/}
                        {/*                <SwitchField*/}
                        {/*                    placeholder="yourstore.myshopify.com"*/}
                        {/*                    {...getFieldProps('active')}*/}
                        {/*                    label={getLangText('realizeOrdersInMyShop')}*/}
                        {/*                    className="switch-form inline-label big-label light-version mt-0 mb-0"*/}
                        {/*                />*/}
                        {/*                <div className="flex items-center mt-2 justify-end">*/}
                        {/*                    <button*/}
                        {/*                        type="button"*/}
                        {/*                        onClick={onSave}*/}
                        {/*                        className={`button primary ml-auto ${isWaiting ? 'loading-button' : ''}`}*/}
                        {/*                    >*/}
                        {/*                        <Loader isLoading={isWaiting}/>*/}
                        {/*                        {getLangText(!+values.is_connected ? 'connectLabel' : 'saveButton')}*/}
                        {/*                    </button>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {!+values.is_connected &&
                            <button
                                type="button"
                                onClick={() => setShowConnectModal(true)}
                                className={`button w-full primary`}
                            >
                                {getLangText('connectWithShopify')}
                            </button>
                        }
                    </div>
                </div>
                {showConnectModal &&
                    <ConnectModal
                        hide={() => setShowConnectModal(false)}
                    />
                }
                {showModal &&
                    <ConfirmModal
                        dialogClassName="small-modal w-420"
                        title={getLangText('wantToSyncProductsWithShopify')}
                        SubTitle={() => <div className="mt-2"
                                             dangerouslySetInnerHTML={{__html: getLangText('wantToSyncProductsWithShopifySubLabel')}}/>}
                        saveButton={getLangText('yesButton')}
                        show={showModal}
                        isWaiting={waitingForProducts}
                        onSubmit={syncProducts}
                        hide={() => setShowModal(false)}
                    />
                }
                {isConfirmModalVisible &&
                    <ConfirmModal
                        title={getLangText('shopifyDisconnect')}
                        saveButton={getLangText('disconnect')}
                        SubTitle={() => (
                            <div className="mt-2">
                                <div>
                                    {getLangText('wantToShopifyDisconnect')}
                                </div>
                                <div>
                                    {getLangText('allProductsFromIntegrationWillBeRemoved')}
                                </div>
                            </div>
                        )}
                        show={isConfirmModalVisible}
                        isWaiting={isWaiting}
                        onSubmit={disconnect}
                        hide={() => setIsConfirmModalVisible(false)}
                    />
                }
            </Wrapper>
        </>
    );
};

export default ShopifyConfiguration;
