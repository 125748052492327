import React, {useContext} from 'react';
import FormActions from "./FormActions";
import useForm from "../../../../../src_shared/form/useForm";
import Loader from "../../../shared/Loader";
import useLang from "../../../../../src_shared/hooks/useLang";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import Ckeditor from "../../../../../src_shared/components/form/ckeditor/Ckeditor";
import Field from "../../../../../src_shared/components/form/field/Field";
import {Helmet} from "react-helmet";
import ShopNameBar from "../../../../routes/shopSettingsRoute/ShopNameBar";
import {useDispatch, useSelector} from "react-redux";
import {ShopSettingsContext} from "../../../../routes/shopSettingsRoute/ShopSettingsRoute";
import useAssetsLang from "../../../shared/helpers/useAssetsLang";

export const custom_config = {
	toolbar: {
		items: [
			'heading',
			'|',
			'bold',
			'italic',
			'|',
			'undo',
			'redo',
		]
	},
}

const ShopSettingsMessages = () => {
	const dispatch = useDispatch();
	const {getLangText} = useLang();
	const lang = useAssetsLang();
	const {setIsChanging} = useContext(ShopSettingsContext);
	const {
		data: {values, setValue},
		form: {isWaiting, getFieldProps},
	} = useForm({
		FormActions,
		params: {},
		requireParentId: false
	});

	const onSave = () => dispatch(FormActions.submitEditForm(values, () => setIsChanging(new Date())))


	return (
		<div className={`settings-page sub-settings-page loader-parent`}>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{`${getLangText('shopSettingsAnnouncementsLabel')} - Selmo`}</title>
			</Helmet>
			<ShopNameBar/>
			<div className="loader-parent">
				<Loader isLoading={isWaiting}/>
				<div className="settings-form ">
					<div className="section-header border-0 mb-0">
						<h1>{getLangText('messagesBarLabel')}</h1>
						<div className="desc">
							{getLangText('addContentWillAppearInTheAnnouncementBarLabel')}
						</div>
					</div>
					<div className="form-info-box w-100 mb-3">
						<div>
							<i className="icon-info-c"/>
						</div>
						<div>
							<div className="title mb-0">
								{getLangText('whereAreMessagesDisplayedLabel')}
							</div>
							<div className="desc">
								{getLangText('whereAreMessagesDisplayedSubLabel')}
							</div>
							<img className="w-sm-75 w-100 mt-3 rounded mb-3" alt="collecitons" src={`/assets/images/multiLanguage/${lang}/promo_bar.webp`}/>
						</div>
					</div>
					<section className="mt-sm-5 mt-4">
						<div className="left-side">
							<h2 className="label">{getLangText('announcementLabel')} 1</h2>
						</div>
						<div className="form-col">
							<Ckeditor
								{...getFieldProps('first_normal_text')}
								setValue={setValue}
								label={getLangText('textLabel')}
								value={values.first_normal_text || ''}
								customConfig={custom_config}
							/>
							<Field
								{...getFieldProps('first_url')}
								value={values.first_url}
								label={getLangText('linkLabel')}
								placeholder={getLangText('typeLinkLabel')}
								setValue={setValue}
								optional
							/>
						</div>
					</section>
					<section>
						<div className="left-side">
							<h2 className="label">{getLangText('announcementLabel')} 2</h2>
						</div>
						<div className="form-col">
							<Ckeditor
								{...getFieldProps('second_normal_text')}
								setValue={setValue}
								value={values.second_normal_text || ''}
								label={getLangText('textLabel')}
								customConfig={custom_config}
							/>
							<Field
								{...getFieldProps('second_url')}
								value={values.second_url}
								label={getLangText('linkLabel')}
								placeholder={getLangText('typeLinkLabel')}
								setValue={setValue}
								optional
							/>
						</div>
					</section>
					<section>
						<div className="left-side">
							<h2 className="label">{getLangText('announcementLabel')} 3</h2>
						</div>
						<div className="form-col">
							<Ckeditor
								{...getFieldProps('third_normal_text')}
								setValue={setValue}
								value={values.third_normal_text || ''}
								label={getLangText('textLabel')}
								customConfig={custom_config}
							/>
							<Field
								{...getFieldProps('third_url')}
								label={getLangText('linkLabel')}
								value={values.third_url}
								placeholder={getLangText('typeLinkLabel')}
								setValue={setValue}
								optional
							/>
						</div>
					</section>
					<hr/>
					<div className="d-flex flex-wrap mt-3">
						<SwitchField
							{...getFieldProps('active')}
							setValue={setValue}
							label={getLangText('activeAnnouncementLabel')}
							id="active"
							className="switch-form inline-label big-label light-version mb-0 mt-0 w-sm-auto w-100"
						/>
						<button
							type="button"
							className={`ml-sm-auto button primary w-sm-auto w-100 mt-sm-0 mt-3 ${isWaiting ? 'loading-button' : ''}`}
							onClick={onSave}
						>
							{isWaiting && <Loader isLoading/>}
							{getLangText('saveButton')}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ShopSettingsMessages;
