import React, {useContext, useEffect} from 'react';
import CreateShopLayout from "../../shared/CreateShopLayout";
import useLang from "../../../../../src_shared/hooks/useLang";
import {Link, useHistory} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {useSelector} from "react-redux";
import {TutorialFinalStepsContext} from "../FinalStepsContext";
import ComponentLoader from "../../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import {Helmet} from "react-helmet";
import {usePostHog} from "posthog-js/react";
import useAssetsLang from "../../../shared/helpers/useAssetsLang";

const TutorialOrder = () => {
    const lang = useAssetsLang();
    const {isLoading} = useContext(TutorialFinalStepsContext);
    const posthog = usePostHog();

    const {getLangText} = useLang();

    useEffect(() => {
        if (posthog) {
            posthog?.capture(`ONBOARDING - go to order page visit`);
        }
    }, [posthog])

    if (isLoading) {
        return (
            <CreateShopLayout>
                <ComponentLoader className="small-loader higher-border-radius"/>
            </CreateShopLayout>
        )
    }

    return (
        <div
            className={`bg-[#eff2f6] sm:py-0 py-6 sm:px-0 px-6 sm:min-h-auto min-h-full sm:h-[100vh] w-[100vw] flex sm:items-center sm:justify-center`}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('goToOrderButton')} - Selmo</title>
            </Helmet>
            <div
                className={`w-full sm:my-0 my-auto sm:p-0 px-6 py-10 rounded-[12px] shadow-default bg-white md:w-[800px] min-h-[450px] md:min-h-[600px] flex flex-col justify-center animate-tutorialSlideAndFadeIn`}>
                <div className="relative inline-flex animate-tutorialSlideAndFadeIn">
                    <img className="mx-auto max-w-[400px] sm:w-auto w-full" alt="selmo order"
                         src={`/assets/images/multiLanguage/${lang}/tutorial/summary.webp`}/>
                </div>
                <div
                    className={`text-center animate-tutorialSlideAndFadeInDelay1 opacity-0 mt-3.5 sm:mt-6`}>
                    <h1 className="sm:text-2xl text-xl font-bold"
                        dangerouslySetInnerHTML={{__html: getLangText('pressButtonGoToOrderLabel')}}/>
                    <h2 className="text-[#9CA3AF] text-sm font-medium mt-3.5"
                        dangerouslySetInnerHTML={{__html: getLangText('testPageWhichSeeYourClientLabel')}}/>
                    <Link
                        className="bg-[#3C61EA] overflow-hidden mt-6 animate-tutorialSlideAndFadeInDelay2 opacity-0 button font-semibold rounded-[8px] px-[22px] py-[12px] text-white transition-all hover:bg-[#1c4ed8]"
                        to={RouterPaths.TutorialBuilding}
                    >
                        {getLangText('finishConfigurationTutorialLabel')}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default TutorialOrder;
