import React from 'react';
import Field from "../../../../../src_shared/components/form/field/Field";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useDispatch, useSelector} from "react-redux";
import FormActions from "../FormActions";
import {
	validateCreditCardNumber, validateCVC,
	validateExpirationDate
} from "../../../../../src_shared/form/validation/ValidationError";
import {createSelector} from "reselect";
import {getStateRoot} from "../formReducer";

function clearNumber(value = '') {
	return value.replace(/\D+/g, '')
}

export function formatCreditCardNumber(value) {
	if (!value) {
		return value
	}

	// const issuer = '' || Payment.fns.cardType(value)
	const issuer = '';
	const clearValue = clearNumber(value)
	let nextValue

	switch (issuer) {
		case 'amex':
			nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
				4,
				10
			)} ${clearValue.slice(10, 15)}`
			break
		case 'dinersclub':
			nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
				4,
				10
			)} ${clearValue.slice(10, 14)}`
			break
		default:
			nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
				4,
				8
			)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`
			break
	}

	return nextValue.trim()
}

export function formatCVC(value) {
	const clearValue = clearNumber(value)
	let maxLength = 4

	return clearValue.slice(0, maxLength)
}

export function formatExpirationDate(value) {
	const clearValue = clearNumber(value)

	if (clearValue.length >= 3) {
		return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
	}

	return clearValue
}

const CardDetails = () => {

	const {getLangText} = useLang();
	const dispatch = useDispatch();
	const {paymentValidationActive, data} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

	const setValues = (field, value) => dispatch(FormActions.data.updateValues({
		card_details: {
			...data.card_details,
			[field]: value
		}
	}))
	const setValue = (field, value) => {
		if (field === 'card_number') {
			setValues(field, formatCreditCardNumber(value))
		} else if (field === 'expiration_date') {
			setValues(field, formatExpirationDate(value))
		} else if (field === 'cvc') {
			setValues(field, formatCVC(value))
		}
	}

	return (
		<div className="mt-3 card-details">
			<div className="row">
				<div className="col-md-12">
					<Field
						name="cardnumber"
						setValue={(field, value) => setValue('card_number', value)}
						value={data.card_details.card_number}
						placeholder={getLangText('cardNumberLabel')}
						label={getLangText('cardNumberLabel')}
						getError={() => !validateCreditCardNumber(data.card_details.card_number)}
						validateErrors={paymentValidationActive}
						extraProps={{
							autocomplete: 'cc-number',
						}}
					/>
				</div>
				<div className="col-md-6 pr-md-1">
					<Field
						name="expiry"
						setValue={(field, value) => setValue('expiration_date', value)}
						placeholder="MM/YY"
						value={data.card_details.expiration_date}
						label={getLangText('expirationDateLabel')}
						getError={() => !validateExpirationDate(data.card_details.expiration_date)}
						validateErrors={paymentValidationActive}
						extraProps={{
							autocomplete: 'expiry',
						}}
					/>
				</div>
				<div className="col-md-6 pl-md-1">
					<Field
						name="cvc"
						type="password"
						placeholder="CVC"
						setValue={(field, value) => setValue('cvc', value)}
						value={data.card_details.cvc}
						label={getLangText('cvcCodeLabel')}
						getError={() => !validateCVC(data.card_details.cvc)}
						validateErrors={paymentValidationActive}
						extraProps={{
							autocomplete: 'cc-csc',
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default CardDetails;
