import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsShopifyActiveModel extends Model {
	getModel() {
		return {
			reserve_items: '1',
		};
	}

	getValidators() {
		return {
			reserve_items: ValidationError.skip,
		};
	}
	buildDTO(data) {
		return {
			reserve_items: data.reserve_items,
		}
	}
}

export default new SettingsShopifyActiveModel();
