import React, {useEffect} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {Link, useLocation} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import VideoPreview from "../../../../src_shared/components/ui/photoPreview/VideoPreview";
import usePhotoPreview from "../../../../src_shared/components/ui/photoPreview/usePhotoPreview";
import {useSelector} from "react-redux";
import ReactPixel from "react-facebook-pixel";
import {parseParams} from "../../../../src_shared/list/ListActions";
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";
import useAssetsLang from "../../shared/helpers/useAssetsLang";

const tutorials = {
    pl: [
        {
            id: 'selmo',
            src: 'https://www.youtube.com/embed/LdkYQ4EFPe4?si=0xdnQbl1LZd8E3xV',
            label: 'howToUseSelmoLabel',
        },
        {
            id: 'payments',
            src: 'https://www.youtube.com/embed/r6EpoPUV-Hc?si=fXDETFbzT2vDeMhn',
            label: 'paymentConfigureSelmoLabel',
        }
    ]
}
const ThankYouPage = () => {
    const {getLangText} = useLang();
    const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();
    const lang = useAssetsLang();
    const {search} = useLocation();
    const query = parseParams(search);

    useEffect(() => {
        if (!!+query.success_payment) {
            setTimeout(() => {
                ReactPixel.track('Purchase', {currency: query.currency, value: query.price / 100})
                window?.gtag('event', 'purchase', {
                    value: query.price / 100,
                    currency: query.currency
                });
            })
        }
    }, [])

    return (
        <div className="bg-white w-full min-h-[100vh] flex items-center justify-center">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('thanksForPaymentLabel')} - Selmo</title>
            </Helmet>
            <div className="text-center">
                <img className="mx-auto mb-3 w-[85px]" alt="selmo" src="/assets/images/selmo-logo.svg"/>
                <div className="text-3xl font-bold mb-1">{getLangText('thanksForPaymentLabel')}</div>
                <div className='text-desc font-medium'>{getLangText('enjoyUsingSelmoLabel')}</div>
                <Link to={RouterPaths.Dashboard} className="button primary mt-4 big-size">
                    {getLangText('goToMainPageLabel')}
                </Link>
                {tutorials[lang] &&
                    <div className="mt-24">
                        <div className="border rounded-md p-3 sm:w-[480px] mx-3 sm:-mx-5">
                            <div className="flex items-center">
                                <div className="text-sm grow text-left">
                                    <div className="font-bold">{getLangText('watchTutorialsLabel')}</div>
                                    <div className="text-desc font-medium">{getLangText('seeHowToUseSelmoLabel')}</div>
                                </div>
                                <div className="hidden sm:block">
                                    <Link
                                        to={RouterPaths.Help}
                                        className="button border-button small-size">
                                        {getLangText('allTutorialsLabel')}
                                    </Link>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 items-start gap-3 sm:gap-4 mt-3">
                                {tutorials[lang].map((item) => (
                                    <button
                                        type="button"
                                        onClick={() => openPreviewModal(item.src)}
                                        className="text-left hover:no-underline"
                                    >
                                        <div
                                            className="border h-[120px] border-[#D1D5DB] rounded-md w-full overflow-hidden">
                                            <img
                                                className="w-full h-full object-cover"
                                                src={`/assets/images/multiLanguage/${lang}/tutorialPageBox/${item.id}.webp`}
                                                alt={item.id}
                                            />
                                        </div>
                                        <div
                                            className="text-xs text-[#4B5563] font-bold mt-2">{getLangText(item.label)}</div>
                                    </button>
                                ))}
                            </div>
                            <div className="sm:hidden">
                                <Link
                                    to={RouterPaths.Help}
                                    className="button border-button w-full mt-4">
                                    {getLangText('allTutorialsLabel')}
                                </Link>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <VideoPreview
                hide={hidePreviewModal}
                show={showPreviewModal}
            />
        </div>
    );
};

export default ThankYouPage;
