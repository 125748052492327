import React, {useEffect, useMemo, useState} from 'react';
import useLang from "../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import useFetchData from "../../../src_shared/hooks/useFetchData";
import {services} from "../../RestServices";
import ComponentLoader from "../../../src_shared/components/ui/componentLoader/ComponentLoader";
import InvoiceDataModal from "./components/InvoiceDataModal";
import {useHistory, useLocation} from "react-router-dom";
import {convertToUserTimeZone} from "../shared/helpers/dateHelpers";
import moment from "moment/moment";
import {useCountdown} from "../../../src_shared/hooks/useCountDown";
import cn from "clsx";
import {SHOP_ORIGIN_PL} from "../shared/enums/ShopOrigins";
import useAssetsLang from "../shared/helpers/useAssetsLang";

const whyWorthItOptions = [
    {
        id: 0,
        title: 'blackWeekWhyWortIt1',
        desc: 'blackWeekWhyWortIt1Desc',
    },
    {
        id: 1,
        title: 'blackWeekWhyWortIt2',
        desc: 'blackWeekWhyWortIt2Desc',
    },
    {
        id: 2,
        title: 'blackWeekWhyWortIt3',
        desc: 'blackWeekWhyWortIt3Desc',
    },
]

const fullYearPrices = [
    {id: 'pln', price: '1495', shortcut: 'zł', oldPrice: '4186', single: '299', totalPrice: '2691'},
    {id: 'eur', price: '495', shortcut: '€', single: '99', oldPrice: '1386', totalPrice: '891'},
    {id: 'dollar', price: '495', shortcut: '$', single: '99', oldPrice: '1386', totalPrice: '891'},
    {id: 'dkk', price: '3495', shortcut: 'kr.', single: '699', oldPrice: '9786', totalPrice: '6291'},
]

export const BLACK_WEEK_PROMO_PRICES = [
    {id: 'pln', price: '2685', shortcut: 'zł', oldPrice: '1794', single: '299', totalPrice: '1196', shop: '149'},
    {id: 'eur', price: '645', shortcut: '€', single: '99', oldPrice: '594', totalPrice: '396', shop: '29'},
    {id: 'dollar', price: '645', shortcut: '$', single: '99', oldPrice: '594', totalPrice: '396', shop: '29'},
    {id: 'dkk', price: '4725', shortcut: 'kr.', single: '699', oldPrice: '4194', totalPrice: '2796', shop: '219'},
]

export const getCorrectPriceCurrency = (locale, array = BLACK_WEEK_PROMO_PRICES) => {
    const findPrice = (id) => array.find((i) => i.id === id);


    if (locale === 'pl') return findPrice('pln');
    if (locale === 'dk') return findPrice('dkk')
    if (locale === 'us') return findPrice('dollar');
    return findPrice('eur');
}


const BlackFriday = () => {

    const {origin} = useSelector((state) => state.session.userData);
    const price = useMemo(() => `${getCorrectPriceCurrency(origin).price} ${getCorrectPriceCurrency(origin).shortcut}`, [origin])
    const [showModal, setShowModal] = useState(false);
    const {getLangText} = useLang();
    const endDate = convertToUserTimeZone(moment('02.12.2024 23:59:59', 'DD.MM.YYYY HH:mm:ss').toDate()).toDate()
    const todayDate = convertToUserTimeZone(moment(new Date(), 'DD.MM.YYYY HH:mm:ss').toDate()).toDate()
    const {days, hours, minutes, seconds} = useCountdown(endDate);
    const [showVideo, setShowVideo] = useState(false);
    const handleShowVideo = () => setShowVideo(true);
    const lang = useAssetsLang();
    const expired = endDate <= todayDate

    const history = useHistory();
    const location = useLocation();

    const defaultSrc = `https://www.youtube.com/embed/jT1-fsyT8G8?si=_V8P5Rt4AtuTKLRA&autoplay=0`
    const autoPlaySrc = `https://www.youtube.com/embed/fa1haaWXzjc?si=-4IKBGaEFCZMl_JG&autoplay=1`

    const [src, setSrc] = useState(defaultSrc)

    const [{isLoading}] = useFetchData({
        rest: services.API_BLACK_WEEK_PROMO_LANDING,
    })

    useEffect(() => {
        const query = new URLSearchParams(location.search);

        if (!!+query.get('play')) {

            setSrc(autoPlaySrc)
            query.delete('play');

            history.replace({
                pathname: location.pathname,
                search: query.toString(),
            });
        }
    }, [location.search, history]);

    return (
        <div className="bg-[#fff] rounded-[5px] shadow-default sm:pt-12 pb-10 overflow-hidden">
            {lang === SHOP_ORIGIN_PL &&
                <div className="w-full sm:w-[750px] mx-auto">
                    <div className="selmo-video-wrapper no-border-sm">
                        {!showVideo ?
                            <div
                                onClick={handleShowVideo}
                                className={cn('absolute top-0 left-0 bottom-0 right-0 group cursor-pointer')}>
                                <img
                                    className="absolute sm:w-auto sm:h-auto w-[84px] h-[84px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 group-hover:scale-105 transition-all duration-200"
                                    src="/assets/images/svg/play-white.svg" alt="play" width={84} height={84}
                                />
                                <img
                                    className="sm:rounded-[20px]"
                                    src={`/assets/images/multiLanguage/${lang}/black-week-cover.webp`}
                                    alt="intro image"
                                    width={1200}
                                    height={620}
                                />
                            </div> :
                            <iframe
                                frameBorder="0" allowFullScreen="" scrolling="no"
                                allow="autoplay;fullscreen"
                                src={autoPlaySrc}
                                style={{
                                    position: 'absolute',
                                    height: '100%',
                                    width: '100%',
                                    left: '0px',
                                    top: '0px'
                                }}
                            />
                        }
                    </div>
                </div>
            }
            <div className={`max-w-[475px] mx-auto sm:px-0 px-[16px] ${origin === 'pl' ? 'sm:mt-16' : ''}`}>
                <div className="flex items-cetner mt-4 mb-3">
                    <div className="text-[#3B82F6] flex items-center font-bold">Black Week</div>
                    <div className="p-2 rounded-[5px] text-xs font-bold text-[#0D3364] ml-auto bg-[#F0FAFF]">
                        <i class="icon-gift-icon-lp mr-1"/>
                        {getLangText('twelveMonthsForThePriceInNine')}
                    </div>
                </div>
                <div
                    className="whitespace-pre-wrap big-text-black-week font-bold text-[26px] sm:text-[32px] leading-[38px] sm:leading-[50px]"
                    dangerouslySetInnerHTML={{
                        __html: getLangText({
                            key: 'saveAndGetSelmoForYear',
                            data: [price, getCorrectPriceCurrency(origin).shortcut]
                        })
                    }}
                />
                <div className="mb-3 mt-sm-5 mt-4">
                    <div className="font-bold text-sm">{getLangText('extendingPlan')}</div>
                    <div className="space-y-4 mt-3 mb-4">
                        {whyWorthItOptions?.map((i) => (
                            <div
                                key={i.id}
                                className={`flex items-start`}
                            >
                                <div>
                                    <i className="icon-tick-c text-[18px] min-w-[18px] mr-2 text-[#5FA5FA]"/>
                                </div>
                                <div>
                                    <div
                                        className="text-[#101827] font-bold text-sm"
                                        dangerouslySetInnerHTML={{__html: getLangText({key: i.title, data: [price]})}}/>
                                    <div
                                        className="font-medium text-sm text-[#4B5563]"
                                        dangerouslySetInnerHTML={{__html: getLangText(i.desc)}}/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    className="bg-[#F6FCFF] rounded-md p-2 flex flex-sm-nowrap flex-wrap items-center mt-8 mb-3">
                    <div>
                        <div
                            className="text-[#0D3364] text-xs">{getLangText('limitedNumberOfPackages')}</div>
                        <div
                            className="text-[#0D3364] font-bold sm:w-auto w-full mb-sm-0 mb-2 sm:text-[16px] text-sm">{getLangText('takeThePromoUntil')}</div>
                    </div>
                    <div className="flex items-center sm:ml-auto space-x-1">
                        {!!+days &&
                            <>
                                <div
                                    className="text-center w-[51px] h-[51px] p-1.5 text-[#101827] rounded-md bg-[#DBF3FF]">
                                    <div className="text-xl font-bold">
                                        {days}
                                    </div>
                                    <div className="uppercase text-[7px] font-bold leading-[8px] tracking-[1px]">
                                        {getLangText('daysLabel')}
                                    </div>
                                </div>
                                <div>:</div>
                            </>
                        }
                        <div className="text-center min-w-[51px] h-[51px] p-1.5 text-[#101827] rounded-md bg-[#DBF3FF]">
                            <div className="text-xl font-bold">
                                {hours}
                            </div>
                            <div className="uppercase text-[7px] font-bold leading-[8px] tracking-[1px]">
                                {getLangText('input_addon_hours')}
                            </div>
                        </div>
                        <div>:</div>
                        <div className="text-center min-w-[51px] h-[51px] p-1.5 text-[#101827] rounded-md bg-[#DBF3FF]">
                            <div className="text-xl font-bold">
                                {minutes}
                            </div>
                            <div className="uppercase text-[7px] font-bold leading-[8px] tracking-[1px]">
                                {getLangText('minutesLabel')}
                            </div>
                        </div>
                        <>
                            <div>:</div>
                            <div
                                className="text-center min-w-[51px] h-[51px] p-1.5 text-[#101827] rounded-md bg-[#DBF3FF]">
                                <div className="text-xl font-bold">
                                    {seconds}
                                </div>
                                <div className="uppercase text-[7px] font-bold leading-[8px] tracking-[1px]">
                                    {getLangText('secondsLabel')}
                                </div>
                            </div>
                        </>
                    </div>
                </div>
                {!expired &&
                    (isLoading ?
                        <ComponentLoader style={{minHeight: '200px'}}/> :
                        <div>
                            <button
                                type="button"
                                onClick={() => setShowModal(true)}
                                style={{fontSize: '16px', borderRadius: 8}}
                                className="button primary w-full medium-size text-center"
                            >
                                {getLangText('extendNow')}
                            </button>
                            <div className="flex justify-end items-center">
                                <img className="mr-[10px] w-[16px] brightness-0 mt-1 block"
                                     src="/assets/images/svg/arrow-black.svg" alt=""/>
                                <div
                                    style={{fontFamily: 'HateYourWriting'}}
                                    className="text-[#000] text-[28px] sm:text-[35px]"
                                    dangerouslySetInnerHTML={{
                                        __html: getLangText({
                                            key: 'saveMoney',
                                            data: [price]
                                        })
                                    }}
                                />
                            </div>
                        </div>)
                }
            </div>
            {showModal &&
                <InvoiceDataModal
                    show={showModal}
                    hideModal={() => setShowModal(false)}
                />
            }
        </div>
    );
};

export default BlackFriday;
