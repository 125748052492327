import useLang from "../../../../../src_shared/hooks/useLang";
import Modal from "react-bootstrap/Modal";
import Loader from "../../loader/Loader";
import React from "react";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";

const SelmoProModal = ({
                           show,
                           hide,
                       }) => {
    const {getLangText} = useLang();

    return (
        <Modal
            dialogClassName={`default-modal w-850 full-black-modal`}
            contentClassName="rounded-xl overflow-hidden"
            show={show}
            onHide={hide}
        >
            <div className="relative"
            >
                <button
                    onClick={hide}
                    type="button"
                    className="absolute text-[#717171] right-3 top-3 w-[32px] h-[32px] min-w-[32px] flex items-center justify-center rounded-full ml-2 bg-[rgba(75,85,99,0.3)]"
                >
                    <i className="icon-cross text-[10px]"/>
                </button>
                <div className="grid md:grid-cols-2">
                    <div className="text-[#fff] p-12 border-r border-[#262626]">
                        <div className="font-bold text-[32px] leading-[45px] mb-6">
                            {getLangText('browseOnlyMessagesWhichYouHaveToAnswer')}
                        </div>
                        <div className="font-bold mb-1">
                            {getLangText('doNotLeaveCustomerWithoutAnswer')}
                        </div>
                        <div className="text-[#757575] font-medium leading-[28px]">
                            {getLangText('weShowYouConversationWhereASystemMessageWasSent')}
                        </div>
                        <Link
                            to={RouterPaths.SelmoPro}
                            style={{fontSize: 14}}
                            className={`bg-[#fff] mt-12 w-full text-center text-nowrap text-[#000] rounded-[8px] text-sm py-[12px] px-3 font-bold button white`}
                        >
                            {getLangText('findOutMoreButton')}
                        </Link>
                        <div className="flex items-center justify-center text-xs font-medium mt-10">
                            {getLangText('availableOnlyIn')}
                            <img
                                className="w-[85px] ml-1"
                                src="/assets/images/svg/selmo-pro-logo.svg" alt=""/>
                        </div>
                    </div>
                    <div className="text-[#fff] messenger-selmo-pro-gradient py-10 px-14 flex flex-col">
                        <div
                            className="text-[22px] leading-[30px] mb-6 whitespace-pre-wrap font-light"
                            dangerouslySetInnerHTML={{__html: getLangText('weWillDisplayConversationThatAreWorthToRespond')}}
                        />
                        <div className="space-y-5">
                            <div className="flex items-end">
                                <div className="w-[32px] h-[32px] min-w-[32px] flex items-center justify-center overflow-hidden rounded-full mr-2 bg-[rgba(75,85,99,0.3)] mb-3 bg-[#FCD34C]">
                                    <img src="/assets/images/svg/messagesSelmoPro/avatar.svg" alt=""/>
                                </div>
                                <div>
                                    <div className="p-3 selmo-pro-message-gradient rounded-[15px] text-sm text-[#fff]">
                                        {getLangText('messengerSelmoProMessage1')}
                                    </div>
                                    <div className="text-[8px] text-[#6B7280] mt-1 ml-[5px]">
                                        {getLangText('clientLabel')}
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-end justify-end">
                                <div className="">
                                    <div className="p-3 relative bg-[rgba(31,33,38,0.75)] rounded-[15px] text-sm text-[#fff] whitespace-pre-wrap">
                                        <div className="opacity-30">{getLangText('messengerSelmoProMessage2')}</div>
                                        <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                                            <img
                                                className="w-[20px]"
                                                src="/assets/images/svg/messagesSelmoPro/eye.svg" alt=""/>
                                        </div>
                                    </div>
                                    <div className="text-[8px] text-[#6B7280] mt-1 ml-[5px]">
                                        {getLangText('systemMessage')}
                                    </div>
                                </div>
                                <div
                                    className="w-[32px] h-[32px] min-w-[32px] flex items-center justify-center rounded-full ml-2 bg-[rgba(75,85,99,0.3)] mb-3">
                                    <i class="icon-settings opacity-70" />
                                </div>
                            </div>
                        </div>
                        <div
                            className="text-[#E5E7EB] font-bold sm:mt-auto mt-10 text-right whitespace-pre-wrap relative">
                            <img
                                className="relative -right-[40px] sm:block hidden"
                                src="/assets/images/svg/messagesSelmoPro/arrow.svg" alt=""/>
                            {getLangText('weSkipSystemMessages')}
                            <img
                                className="ml-auto mt-1"
                                src="/assets/images/svg/messagesSelmoPro/pro.svg" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SelmoProModal;