import createReducer from "../../shared/helpers/createReducer";
import DataReducer from "../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../src_shared/filters/filterReducer";
import history from "../../../history";
import {parseParams} from "../../../../src_shared/list/ListActions";
import moment from "moment";
import {ALL_ORDERS_VIEW, INTERVAL} from "../../shared/enums/OrderViewTypes";
import {ORDER_PACKED_FILTER_ALL} from "../../shared/enums/PackedStatuses";
import {
	defaultTableColumn, ORDER_TABLE_COLUMN,
} from "../list/components/table/OrderTableColumnEnums";
import {getFromLS} from "../../shared/helpers/ls";
import {ORDER_PRODUCTS_PAID_FILTER_ALL} from "../../shared/enums/PaidStatuses";
import {ORDER_STATUES} from "../../shared/enums/OrderStatuses";
import {ORDER_PAYMENT_STATUES} from "../../shared/enums/PaymentStatuses";
import {ORDER_LABEL_ALL} from "../../shared/enums/OrdersLabelStatuses";
import {ORDER_INVOICE_ALL} from "../../shared/enums/OrdersInvoiceStatuses";
import {ProductsSourceTypes} from "../../shared/enums/ProductSourceType";

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams)

const preparedPayments = parsedParams['payment[]'] || [];
const preparedEmployees = parsedParams['employees[]'] || [];
const preparedProductType = parsedParams['productType[]'] || [];
const preparedShipping = parsedParams['shipping[]'];
const preparedTag = parsedParams['tag[]'];
const preparedDiscounts = parsedParams['discounts[]'];
const preparedCountry = parsedParams['country[]'];
const preparedStatuses = parsedParams['status[]']?.map((i) => +i) || [];
const preparedViewType = !!queryParams ? (parsedParams.dateFrom ? INTERVAL : ALL_ORDERS_VIEW) : INTERVAL;
const preparedPacked = parsedParams.isPacked || ORDER_PACKED_FILTER_ALL;
const preparedPaid = parsedParams.products_paid || ORDER_PRODUCTS_PAID_FILTER_ALL;
const preparedLabel = parsedParams.label || ORDER_LABEL_ALL;
const preparedInvoice = parsedParams.invoice || ORDER_INVOICE_ALL;
const preparedNip = parsedParams.nip || 'all';
const preparedPrinted = parsedParams.printed || 'all';
const preparedLabelPrinted = parsedParams.label_printed || 'all';
const preparedMrAutomatePaused = parsedParams.mr_automate_paused || 'all';
const preparedPriceFrom = parsedParams.priceFrom || '';
const preparedPriceTo = parsedParams.priceTo || '';
const preparedProductsFrom = parsedParams.productsFrom || '';
const preparedProductsTo = parsedParams.productsTo || '';

const LSTableColumn = getFromLS('tableColumn')
const preparedDefaultTableColumnSelectAll = getFromLS('tableColumnSelectAll') || ORDER_TABLE_COLUMN.length === LSTableColumn?.length;

const getPreparedDefaultTableColumn = () => {
	if (LSTableColumn) {
		return LSTableColumn;
	} else {
		return defaultTableColumn;
	}
}

const preparedDate = (initialState, paramsDate) => {
	if (!!queryParams) {
		if (parsedParams[paramsDate]) {
			return moment(parsedParams[paramsDate]).toDate()
		} else {
			return null;
		}
	} else {
		return initialState;
	}
}

export const getStateRoot = (state) => state.orders.filters;

export const getInitState = () => DataReducer.getInitState({
		statuses: preparedStatuses || [],
		filtersSelectAll: preparedStatuses.length === ORDER_STATUES.length,
		payments: preparedPayments || [],
		employees: preparedEmployees || [],
		shipping: preparedShipping || [],
		tag: preparedTag || [],
		discounts: preparedDiscounts || [],
		country: preparedCountry || [],
		paymentsSelectAll: preparedPayments.length === ORDER_PAYMENT_STATUES.length,
		shippingSelectAll: false,
		tagSelectAll: false,
		discountsSelectAll: false,
		countrySelectAll: false,
		tableColumn: getPreparedDefaultTableColumn(),
		tableColumnSelectAll: preparedDefaultTableColumnSelectAll,
		fromDate: preparedDate(moment(new Date()).subtract(1, 'M').toDate(), 'dateFrom'),
		toDate: preparedDate(new Date(), 'dateTo'),
		viewType: preparedViewType,
		isPacked: preparedPacked,
		isPaid: preparedPaid,
		label: preparedLabel,
		invoice: preparedInvoice,
		nip: preparedNip,
		printed: preparedPrinted,
		label_printed: preparedLabelPrinted,
		mr_automate_paused: preparedMrAutomatePaused,
		priceFrom: preparedPriceFrom,
		priceTo: preparedPriceTo,
		productsFrom: preparedProductsFrom,
		productsTo: preparedProductsTo,
		productType: preparedProductType,
		productTypeAll: preparedProductType.length === ProductsSourceTypes.length,
	},
	{
		statuses: [],
		filtersSelectAll: false,
		payments: [],
		shipping: [],
		employees: [],
		tag: [],
		discounts: [],
		country: [],
		paymentsSelectAll: false,
		shippingSelectAll: false,
		countrySelectAll: false,
		tagSelectAll: false,
		discountsSelectAll: false,
		tableColumn: getPreparedDefaultTableColumn(),
		tableColumnSelectAll: preparedDefaultTableColumnSelectAll,
		fromDate: moment(new Date()).subtract(1, 'M').toDate(),
		toDate: moment().toDate(),
		viewType: INTERVAL,
		isPacked: ORDER_PACKED_FILTER_ALL,
		isPaid: ORDER_PRODUCTS_PAID_FILTER_ALL,
		label: ORDER_LABEL_ALL,
		invoice: ORDER_INVOICE_ALL,
		nip: 'all',
		printed: 'all',
		label_printed: 'all',
		mr_automate_paused: 'all',
		priceFrom: '',
		priceTo: '',
		productsFrom: '',
		productsTo: '',
		productType: [],
		productTypeAll: false,
	}
)

export const prefix = 'CMS_ORDER_FILTERS_';

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
