import React, {useState} from 'react';
import cn from "clsx";
import OwnerIcon from "../../../../../src_shared/components/ui/Iocns/OwnerIcon";
import ClientIcon from "../../../../../src_shared/components/ui/Iocns/ClientIcon";
import Button from "../../../../../src_shared/components/ui/Button/Button";
import useLang from "../../../../../src_shared/hooks/useLang";
import {ONBOARDING_CREATE_SHOP_VIEW, ONBOARDING_CUSTOMER_TYPE_VIEW} from "../CreateShopFirstStep";

const OWNER_TYPE = 'OWNER_TYPE';
const CUSTOMER_TYPE = 'CUSTOMER_TYPE';

const options = [
    {id: CUSTOMER_TYPE, label: 'shopClientLabel', desc: 'wantToShop'},
    {id: OWNER_TYPE, label: 'shopOwnerLabel', desc: 'wantToTestSystem'},
]
const AccountTypeOptions = ({setActiveView, setInternalView}) => {
    const {getLangText} = useLang();

    const handleSubmit = (activeType) => {
        if (activeType === CUSTOMER_TYPE) {
            setInternalView(ONBOARDING_CUSTOMER_TYPE_VIEW)
        } else {
            setActiveView(ONBOARDING_CREATE_SHOP_VIEW)
        }
    };

    return (
        <>
            <div className="w-full sm:w-[460px] mx-auto animate-tutorialSlideAndFadeInDelay2 opacity-0">
                <div className="grid sm:grid-cols-2 gap-3 sm:gap-10">
                    {options.map((option) => (
                        <button
                            key={option.id}
                            onClick={() => handleSubmit(option.id)}
                            type="button"
                            className="cursor-pointer text-left group"
                        >
                            <div
                                className={cn('relative border-2 sm:p-2 p-6 rounded-[12px] flex items-center sm:justify-center sm:h-[170px] transition-all group-hover:border-[#d8d8d8]')}>
                                {option.id === OWNER_TYPE ?
                                    <img
                                        className="sm:w-[70px] w-[42px]"
                                        width={70} src="/assets/images/svg/shop_on.svg" alt=""/>
                                    :
                                    <img
                                        className="sm:w-[70px] w-[42px]"
                                        width={70} src="/assets/images/svg/cart_on.svg" alt=""/>
                                }
                                <div className="sm:hidden flex sm:ml-2 ml-3.5 items-center grow">
                                    <div>
                                        <div
                                            className="font-bold text text-[#101827]">{getLangText(option.label)}</div>
                                        <div className="font-medium text-sm text-desc"
                                             dangerouslySetInnerHTML={{__html: getLangText(option.desc)}}/>
                                    </div>
                                    <div
                                        className="w-[30px] h-[30px] bg-[#F0FAFF] right-0 relative transition-all rounded-full ml-auto flex items-center justify-center group-hover:-right-1">
                                        <i className="icon-arrows font-bold block text-[10px] text-[#5FA5FA] -rotate-90"/>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 sm:flex hidden items-center">
                                <div>
                                    <div
                                        className="font-bold text text-[#101827]">{getLangText(option.label)}</div>
                                    <div className="font-medium text-sm text-desc"
                                         dangerouslySetInnerHTML={{__html: getLangText(option.desc)}}/>
                                </div>
                                <div
                                    className="w-[30px] h-[30px] bg-[#F0FAFF] right-0 relative transition-all rounded-full ml-auto flex items-center justify-center group-hover:-right-1">
                                    <i className="icon-arrows font-bold block text-[10px] text-[#5FA5FA] -rotate-90"/>
                                </div>
                            </div>
                        </button>
                    ))}
                </div>
            </div>
        </>
    );
};

export default AccountTypeOptions;
