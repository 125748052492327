import React from 'react';
import {useSelector} from "react-redux";

const ProImageBox = ({className = 'ml-1'}) => {
    const {userData} = useSelector((state) => state.session);
    // if (!!+userData.selmo_pro_active) return null;
    return <img className={`w-[20px] inline-block mb-[2px] ${className}`} src="/assets/images/svg/pro.svg" alt=""/>
};

export default ProImageBox;
