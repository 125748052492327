import React from 'react';
import {notificationsBenefits} from "../../../../notifications/landing/Landing";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";
import useLang from "../../../../../../src_shared/hooks/useLang";
import useAssetsLang from "../../../../shared/helpers/useAssetsLang";

const PromoNotificationBox = () => {
    const {getLangText} = useLang();
    const lang = useAssetsLang();
    return (
        <div className="mt-4 p-3 rounded-[10px] bg-[#F6FCFF] border border-[#D7EAFE] flex items-center flex-wrap mb-3">
            <div className="max-w-[410px]">
                <div
                    className="text-lg font-bold mb-1">{getLangText('sendMoreNotificationsInSelmoLabel')}</div>
                <div className="space-y-2 mt-3 mb-3">
                    {notificationsBenefits.slice(0,2).map((i) => (<div
                            key={i.id}
                            className="flex items-start"
                        >
                            <div className="mr-2 -mt-1">
                                <i className="icon-tick-c text-[#5FA5FA] text-lg"/>
                            </div>
                            <div>
                                <div
                                    className="text-[#101827] font-medium text-sm">{getLangText(i.title)}</div>
                            </div>
                        </div>
                    ))}
                </div>
                <Link
                    className="text-[#3C61EA] text-xs font-bold hover:text-[#1c4ed8] hover:no-underline transition-all"
                    to={RouterPaths.Notifications}
                >
                    {getLangText('goToNotificationsLabel')}
                    <i className="icon-arrows -rotate-90 inline-block text-[8px] ml-1 relative bottom-[1px]" />
                </Link>
            </div>
            <div className="sm:ml-auto sm:w-auto w-full sm:mt-0 mt-3.5">
                <div
                    className={`relative sm:h-[150px] w-full sm:w-[150px] rounded-[10px]`}>
                    <img
                        className="h-full w-full sm:w-auto object-cover rounded-[10px]"
                        src={`/assets/images/multiLanguage/${lang}/app/apka-top.jpeg`}
                        alt="preview"
                    />
                </div>
            </div>
        </div>
    );
};

export default PromoNotificationBox;
