import React, {useEffect, useState} from 'react';
import TrackingInput from "./components/TrackingInput";
import {services} from "../../../RestServices";
import useLang from "../../../../src_shared/hooks/useLang";
import {convertToUserTimeZone} from "../../shared/helpers/dateHelpers";
import api from "../../../../services/axios/axios";
import {selmoUrl} from "../../../../src_shared/api/api";
import SelmoAppAd from "../summaryView/components/SelmoAppAd";
import {useSelector} from "react-redux";
import {
    SHOP_ORIGIN_EN,
    SHOP_ORIGIN_ES,
    SHOP_ORIGIN_FR,
    SHOP_ORIGIN_IT,
    SHOP_ORIGIN_PL,
    SHOP_ORIGIN_PT,
    SHOP_ORIGIN_SK
} from "../../shared/enums/ShopOrigins";

const TrackingPage = ({match}) => {
    const {trackingId} = match.params;
    const {getLangText} = useLang();

    const [isLoading, setIsLoading] = useState(!!trackingId);
    const [data, setData] = useState({});

    const {lang} = useSelector((state) => state.i18n);

    const preparedLang = () => {
        if (lang === SHOP_ORIGIN_PL) return SHOP_ORIGIN_PL
        if (lang === SHOP_ORIGIN_IT) return SHOP_ORIGIN_IT
        if (lang === SHOP_ORIGIN_FR) return SHOP_ORIGIN_FR
        if (lang === SHOP_ORIGIN_SK) return SHOP_ORIGIN_SK
        if (lang === SHOP_ORIGIN_PT) return SHOP_ORIGIN_PT
        if (lang === SHOP_ORIGIN_ES) return SHOP_ORIGIN_ES
        return SHOP_ORIGIN_EN;
    }

    const fetchData = async () => {
        setIsLoading(true);

        try {
            const response = await api.get(`${selmoUrl}/${services.API_CART_LABEL_TRACKING}/${trackingId}`)
            setData(response.data);

        } catch (e) {

        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (trackingId) {
            fetchData()
        }
    }, [trackingId]);

    return (
        <div className="sm:py-20 py-10 lg:px-0 px-6 relative min-h-[100vh] bg-[#f9fafb]">
            <img
                className="absolute left-0 top-0 w-full h-full object-cover max-h-[220px] sm:max-h-[300px]"
                width={650}
                height={530} src="/assets/images/svg/background-tracking.svg" alt="background color"
            />
            <div className="max-w-[650px] mx-auto relative z-[1]">
                <img
                    className="h-[28px] mb-sm-5 mb-3"
                    height={28}
                    src="/assets/images/selmo-logo.svg"
                    alt=""
                />
                <TrackingInput
                    data={data}
                    trackingId={trackingId}
                    isLoading={isLoading}
                />
                <div className="mt-6">
                    <div className="">
                        <a
                            href={`https://onelink.to/selmo?utm_source=selmo&utm_medium=tracking&utm_campaign=${trackingId}`}
                            target="_blank"
                            className="w-full relative transition-all hover:no-underline rounded-[12px] flex flex-col py-6 px-[18px] md:p-9 sm:min-h-[340px]">
                            <img
                                className="absolute top-0 left-0 right-0 bottom-0 object-cover rounded-[12px] sm:rounded-[20px]"
                                src="/assets/images/tracking-ad.webp" alt=""/>
                            <div className="relative">
                                <img
                                    className="md:h-[26px] h-[22px] sm:mb-6 mb-[12px] md:mt-4 mt-[0px]"
                                    src="/assets/images/selmo-white.svg" alt=""/>
                                <div
                                    className="sm:text-[28px] text-sm whitespace-pre-wrap sm:leading-[35px] text-[#fff] font-bold"
                                    dangerouslySetInnerHTML={{__html: getLangText('allOrderInOnePlace')}}
                                />
                                <div
                                    className="inline-block bg-[#fff] rounded-[22px] font-bold text-[#1C37D1] md:text-[16px] text-sm py-2 px-3 mt-3.5 md:mt-6">
                                    {getLangText('downloadApplicationLabel')}
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="mt-6">
                        <div className="bg-[#fff] shadow-default-small rounded-[12px] sm:rounded-[20px] px-6 sm:px-12 py-6 sm:py-10 relative">
                            <div className="font-bold text-xl mb-4">
                                {getLangText('shipmentHistory')}
                            </div>
                            {isLoading ?
                                <div className="space-y-5">
                                    {[...Array(5)].map((_, index) => (
                                        <div
                                            key={index}
                                            className="flex">
                                            <div
                                                className="animate-pulse h-[32px] bg-gray-200 rounded-full dark:bg-gray-700 w-[32px] mr-1"/>
                                            <div className="grow">
                                                <div
                                                    className="animate-pulse h-[24px] bg-gray-200 rounded-md dark:bg-gray-700 w-[240px]"/>
                                                <div
                                                    className="animate-pulse h-[20px] mt-2 bg-gray-200 rounded-md dark:bg-gray-700 w-[200px]"/>
                                            </div>
                                        </div>
                                    ))}
                                </div> :
                                <div>
                                    {data?.items?.length > 0 ?
                                        <div className="employee-history tracking-history">
                                            {data?.items?.map((item, index) => (
                                                <div
                                                    key={item.id}
                                                    className={`flex ${index === 0 && !item.end_status ? 'blue-border' : ''}`}
                                                >
                                                    <div
                                                        className={`z-[1] border-[2px] text-xs text-[#fff] w-[32px] flex items-center justify-center h-[32px] min-w-[32px] rounded-full overflow-hidden mr-2 border-[#fff] ${(index === 0 && !item.end_status) ? 'bg-[#2464EB] shadow-[0_0_1px_1px_#2464EB]' : 'bg-[#34D299] shadow-[0_0_1px_1px_#34D299]'}`}>
                                                        {(index === 0 && !item.end_status) ?
                                                            <i className="icon-wysylka text-sm"/> :
                                                            <i className="icon-tick"/>
                                                        }
                                                    </div>
                                                    <div className="">
                                                        <div
                                                            className="font-bold leading-[22px]">{item.description}</div>
                                                        <div
                                                            className="text-[#9CA3AF] text-sm font-medium">
                                                            {item.city && `${item.city}, `}
                                                            {convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm:ss')}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div> :
                                        <div className="text-center py-6">
                                            <div className="font-bold mb-2">
                                                {getLangText('error')}
                                            </div>
                                            <div className="font-medium whitespace-pre-wrap text-sm text-desc">
                                                {getLangText('noPackageHistory')}
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrackingPage;
