import React, {useEffect, useState} from "react";
import useForm from "../../../../../src_shared/form/useForm.js";
import FormActions from "./FormActions.js";
import Loader from "../../../shared/Loader.jsx";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField.jsx";
import {batch, useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import SharedEditProductForm from "../../shared/SharedEditProductForm.jsx";
import ConfirmModal from "../../../live/details/default/summary/export/ConfirmModal.jsx";
import {useHistory, useParams} from "react-router-dom";
import useLang from "../../../../../src_shared/hooks/useLang";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {services} from "../../../../RestServices";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import {RouterPaths} from "../../../../routes/RouterPath";
import {
    MODA_AND_SZYK_SHOP_ID,
} from "../../../../../src_shared/enums/TrustedShopsIds";
import {createSelector} from "reselect";
import {getStateRoot} from "./formReducer";
import api from "../../../../../services/axios/axios";
import AutomationReserveListModal from "../components/AutomationReserveListModal";
import SubmitButton from "./components/SubmitButton";
import ChangeProductPriceInOrdersModal from "./components/ChangeProductPriceInOrdersModal";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
    USER_PERMISSIONS_PRODUCTS_EDIT
} from "../../../shared/enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../routes/UserPermissionsWrapper";

const ProductForm = () => {
    const params = useParams()
    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [waitingForCreateCopy, setWaitingForCreateCopy] = useState(false);
    const [openChangePriceInAllOrdersModal, setOpenChangePriceInAllOrdersModal] = useState(false);
    const {userData} = useSelector((state) => state.session);
    const {
        openSaveConfirmModal,
        automaticReservationListModal
    } = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const hideAutomaticListReservationModal = () => dispatch(FormActions.setOpenAutomaticReserveListModal(false, []));
    const hideSaveConfirmModal = () => dispatch(FormActions.setOpenSaveConfirmModal(false))
    const showSaveConfirmModal = () => dispatch(FormActions.setOpenSaveConfirmModal(true))

    const history = useHistory();


    const beforeDestroy = () => {
        batch(() => {
            dispatch(FormActions.modal.hideModal());
            dispatch(FormActions.validation.deactivateValidation())
        })
    };

    const {
        data: {values, setValue, updateValues, setNestedValue, defaultValues},
        form: {isWaiting, onSave, getFieldProps, deleteWithoutAsk},
        validation,
        modal,
    } = useForm({
        FormActions,
        params,
        beforeDestroy,
    });

    const isEditEnabled = (!+values.ecommerce_type || +values.ecommerce_type === 3)

    const createProductCopy = async () => {
        setWaitingForCreateCopy(true)
        try {
            const {data} = await api.post(`${selmoUrl}/${services.API_CREATE_PRODUCT_COPY}/${values.id}`)
            if (data.id) {
                history.push(`${RouterPaths.ProductsList}/edytuj/${data.id}`)
                dispatch(showAlert('yuhuLabel', 'successfulCreatedProductCopyLabel'))
                setOpenConfirmModal(false)
            }

        } catch (e) {
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
        } finally {
            setWaitingForCreateCopy(false);
        }
    }

    const handleSave = () => {
        if (+userData.id === MODA_AND_SZYK_SHOP_ID) {
            showSaveConfirmModal()
        } else {
            onSave();
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.querySelector('main').classList.remove('table-page')
    }, []);

    return (
        <div className="loader-parent">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{values.name} - {getLangText('editProductLabel')} - Selmo</title>
            </Helmet>
            <Loader isLoading={isWaiting}/>
            <div className="settings-form p-0 mt-3">
                {+userData.selmo_boss === 69 &&
                    <div className="flex items-center space-x-4 default-shadow-box bigger-y-padd d-block  mb-3">
                        <a
                            className="button primary small-size relative group"
                            href={`https://app.selmo.io/admin/products/${userData.id}/${values.id}`}
                            target="_blank"
                        >
                            <span className="relative z-1">Sprawdź historię</span>
                        </a>
                    </div>
                }
                <SharedEditProductForm
                    FormActions={FormActions}
                    getFieldProps={getFieldProps}
                    setValue={setValue}
                    setNestedValue={setNestedValue}
                    values={values}
                    validation={validation}
                    updateValues={updateValues}
                    defaultValues={defaultValues}
                    isWaiting={isWaiting}
                />
                <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_PRODUCTS_EDIT]}>
                    <div className="default-shadow-box big-padd d-block">
                        <div className="items-center flex-wrap sm:flex grid grid-cols-2 sm:gap-0 gap-3">
                            {isEditEnabled &&
                                <>
                                    <button
                                        type="button"
                                        className="button border-button big-size left-icon tooltip-parent"
                                        onClick={modal.showModal}
                                        disabled={!!values.is_product_used}
                                    >
                                        {!!values.is_product_used &&
                                            <div className="tooltip-content text-center"
                                                 dangerouslySetInnerHTML={{__html: getLangText('toRemoveProductCannotBeOnBasketLabel')}}/>
                                        }
                                        <i className="icon-bin"/>
                                        {getLangText('removeButton')}
                                    </button>
                                    <button
                                        className="button sm:ml-[8px] border-button big-size left-icon"
                                        type="button"
                                        onClick={() => setOpenConfirmModal(true)}
                                    >
                                        <i className="icon-copy"/>
                                        {getLangText('createCopyLabel')}
                                    </button>
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={
                                            <Tooltip
                                                className="small-size"
                                                id="changeProductPriceInOrdersLabel"
                                            >
                                                {getLangText('changeProductPriceInOrdersLabel')}
                                            </Tooltip>
                                        }
                                    >
                                        <button
                                            className="button sm:ml-[8px] border-button big-size"
                                            type="button"
                                            onClick={() => setOpenChangePriceInAllOrdersModal(true)}
                                        >
                                            {getLangText('changeProductPriceLabel')}
                                        </button>
                                    </OverlayTrigger>
                                </>
                            }
                            <SwitchField
                                {...getFieldProps('active')}
                                setValue={setValue}
                                label={getLangText('activeLabel')}
                                id="active"
                                className="switch-form inline-label justify-center sm:ml-auto big-label light-version mb-0 sm:mr-8"
                            />
                            <SubmitButton
                                values={values}
                                handleSave={handleSave}
                                isEditEnabled={isEditEnabled}
                            />
                        </div>
                    </div>
                </UserPermissionsWrapper>
            </div>
            {modal.modalVisible &&
                <ConfirmModal
                    title={getLangText('removeProductLabel')}
                    SubTitle={() => <span dangerouslySetInnerHTML={{
                        __html: getLangText({
                            key: 'askForRemoveProductWithNameLabel',
                            data: [values.name]
                        })
                    }}/>}
                    saveButton={getLangText('removeButton')}
                    show={modal.modalVisible}
                    isWaiting={false}
                    onSubmit={deleteWithoutAsk}
                    hide={modal.hideModal}
                    saveButtonClass="danger"
                />
            }
            {openConfirmModal &&
                <ConfirmModal
                    title={getLangText('createProductCopyTitle')}
                    saveButton={getLangText('yesButton')}
                    show={openConfirmModal}
                    isWaiting={waitingForCreateCopy}
                    onSubmit={createProductCopy}
                    hide={() => setOpenConfirmModal(false)}
                />
            }
            {openChangePriceInAllOrdersModal &&
                <ChangeProductPriceInOrdersModal
                    show={openChangePriceInAllOrdersModal}
                    hide={() => setOpenChangePriceInAllOrdersModal(false)}
                    id={values.id}
                />
            }
            {openSaveConfirmModal &&
                <ConfirmModal
                    title="Czy na pewno chcesz zapisać?"
                    saveButton={getLangText('yesButton')}
                    show={openSaveConfirmModal}
                    isWaiting={isWaiting}
                    onSubmit={onSave}
                    hide={hideSaveConfirmModal}
                />
            }
            {automaticReservationListModal.visible &&
                <AutomationReserveListModal
                    modalVisible={automaticReservationListModal.visible}
                    values={automaticReservationListModal.items}
                    hideModal={hideAutomaticListReservationModal}
                />
            }
        </div>
    );
};

export default ProductForm;



