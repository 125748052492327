import React, {useEffect} from 'react';
import SearchChat from "./components/SearchChat.jsx";
import {batch, useDispatch, useSelector} from "react-redux";
import {
	getMessagesList,
	setListPage,
	setActiveConversation,
	updateFieldInItem,
	showNewMessageButton,
	nextListPage,
	updateMessageList, messengerChatListReset,
} from "./ChatListActions";
import StatusBox from "./components/StatusBox.jsx";
import {useHistory, useParams} from "react-router-dom";
import Loader from "../loader/Loader.jsx";
import InfiniteScroll from "react-infinite-scroll-component";
import {odmiana} from "../helpers/odmiana";
import {getDate} from "../chatBox/components/Message.jsx";
import {addOrderModalReset, addOrderModalToggleVisibility} from "../addOrderModal/AddOrderModalActions";
import {messengerOrdersReset, orderModalToggleVisibility} from "../orderModal/OrderModalActions";
import useWindowDimensions from "../hooks/useWindowDimensions/useWindowDimensions";
import {chatBoxReset, setRightSideVisible} from "../chatBox/ChatBoxActions";
import useLang from "../../../../src_shared/hooks/useLang";
import ClientAvatar from "../../live/shared/Avatar";
import {MessengerDisplayType} from "./ChatListReducer";
import {RouterPaths} from "../../../routes/RouterPath";

export const statuses = [
	{id: null, name: 'mainFolderLabel', color: ''},
	{id: 0, name: 'allLabel', color: 'default'},
	{id: 1, name: 'inquiryLabel', color: 'grey'},
	{id: 2, name: 'customerBasketOrderLabel', color: 'blue'},
	{id: 3, name: 'openBasketLabel', color: 'green'},
	{id: 4, name: 'readyLabel', color: 'green'},
	{id: 5, name: 'noResponse', color: 'grey'},
]

const ChatList = ({messengerRef}) => {
	const controller = new AbortController();
	const signal = controller.signal;

	const dispatch = useDispatch();
	const history = useHistory();
	const {getLangText} = useLang();
	const {lang} = useSelector((state) => state.i18n);
	const {userData} = useSelector((state) => state.session);
	const {conversationId} = useParams();

	const getMessages = (withLoader) => dispatch(getMessagesList(withLoader, signal))

	const {
		items,
		isLoading,
		hasMoreMessage,
		noResults,
		isNewMessageButtonVisible,
		displayType,
		activeConversation
	} = useSelector((state) => state.messenger.chatList)

	const {width} = useWindowDimensions();

	const setVisibleRightSide = (toggle) => dispatch(setRightSideVisible(toggle))

	const getConversationItemClass = (item) => {
		const activeClass = conversationId === item.id ? 'active' : '';
		const newMessageClass = item.viewed === '0' ? 'new' : '';
		return `single-conversation ${activeClass} ${newMessageClass}`
	}

	const onNextPage = () => {
		dispatch(nextListPage())
		getMessages(false)
	}

	const redirectToMessages = (item) => {
		history.push(`/wiadomosci/${item.id}`)

		batch(() => {
			dispatch(chatBoxReset());
			dispatch(updateFieldInItem('viewed', '1', item.id));
			dispatch(setActiveConversation(item));
			dispatch(addOrderModalToggleVisibility(false));
			dispatch(orderModalToggleVisibility(false));
		})
	}

	const handleConversationClick = (item) => {
		redirectToMessages(item)
		if (width < 991) {
			setVisibleRightSide(true)
		}
	}

	const getNewMessageButtonClick = () => {
		dispatch(showNewMessageButton(false))
		dispatch(setListPage(1))
		dispatch(updateMessageList([]));
		getMessages(true)
	}

	useEffect(() => {
		dispatch(setListPage(1))
		dispatch(updateMessageList([]));
		getMessages(true)
		if (displayType === MessengerDisplayType.ONLY_USER) {
			history.replace(RouterPaths.Messenger)
		}
	}, [displayType])

	useEffect(() => () => {
		batch(() => {
			dispatch(addOrderModalReset())
			dispatch(messengerOrdersReset())
		})
	}, [])

	useEffect(() => {
		return () => {
			dispatch(messengerChatListReset())
			controller.abort();
		}
	}, [])

	useEffect(() => {
		if (width < 767 && conversationId) {
			setVisibleRightSide(true)
		} else if (width < 767 && !conversationId) {
			setVisibleRightSide(false);
		}
	}, [width, conversationId])

	return (
		<div className="left-panel">
			<SearchChat
				getMessages={getMessages}
				messengerRef={messengerRef}/>
			<div className="chat-list-container">
				{isNewMessageButtonVisible &&
					<button
						type="button"
						className="new-messages-button"
						onClick={getNewMessageButtonClick}
					>
						<i className="icon-arrow3"/>
						{getLangText('newMessagesLabel')}
					</button>
				}
				{!isLoading && items?.length === 0 ?
					<div className="no-results-text mt-4">
						<img
							width={100}
							src="/assets/images/empty/clients-empty.svg" alt=""/>
						<div className="font-medium text-desc text-sm">{getLangText('noConversationLabel')}</div>
					</div> :
					<div className="chat-list-wrapper loader-parent" id="listScrollableDiv">
						<InfiniteScroll
							dataLength={items?.length}
							next={onNextPage}
							hasMore={hasMoreMessage}
							loader={!noResults && <div className="scroll-list-loader">{getLangText('loadingLabel')}</div>}
							scrollableTarget="listScrollableDiv"
						>
							{isLoading ?
								<Loader/> :
								items && items.map((item) => (
									<div
										key={item.id}
										className={getConversationItemClass(item)}
										onClick={() => handleConversationClick(item)}
									>
										<div>
											<ClientAvatar
												photo={item.user_avatar}
												type={item.facebook_conversation_id ? 'facebook' : 'instagram'}
												fbLogo={userData.facebook_id && userData.instagram_id}
											/>
										</div>
										<div className="content">
											<div className="name">
												<span>{item.user_name}</span>
												<div className="date">{getDate(item.last_update, lang)}</div>
											</div>
											<div
												className="description">{item.last_message ? item.last_message : getLangText('sentMessageLabel')}</div>
											<div className="status-bar">
												<StatusBox statusId={item.conversation_status}/>
												<div
													className="status default">{item.orders_count}
													{' '}
													{odmiana(item.orders_count, getLangText('customerBasketOrderLabel')?.toLowerCase(), getLangText('asideOrdersLabel')?.toLowerCase(), getLangText('ordersLabel')?.toLowerCase())}
												</div>
											</div>
										</div>
									</div>
								))
							}
						</InfiniteScroll>
					</div>
				}
			</div>
		</div>
	);
};

export default ChatList;
