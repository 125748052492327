import React, {useState} from 'react';
import SelectField from "./SelectField";
import axios from "axios";
import {selmoUrl} from "../../../api/api";
import useLang from "../../../hooks/useLang";
import api from "../../../../services/axios/axios";

const AsyncSelectField = ({
                              value,
                              label,
                              className,
                              placeholder,
                              setValue,
                              rest,
                              optionsParser,
                              isSearchable,
                              name,
                              updateValues,
                              defaultValueParser,
                              defaultValue,
                              formatOptionLabel,
                              validateErrors,
                              getError,
                              validationData,
                              extraProps,
                              showLabel,
                              withLackOption,
                              getLoaderOption,
                              disabled
                          }) => {


    const [options, setOptions] = useState([{label: '', value: ''}])
    const {getLangText} = useLang();
    const getOptions = async () => {
        // setOptions([getLoaderOption(getLangText)])
        try {
            const {data} = await api.get(`${selmoUrl}/${rest}`)
            if (withLackOption) {
                setOptions([{name: getLangText('lackLabel'), id: null}, ...data.items]);
            } else {
                setOptions(data.items);
            }
        } catch (e) {
            console.warn('Cannot get options')
        }
    }

    const preparedOptions = optionsParser(options);
    const preparedDefaultValue = defaultValueParser(defaultValue);

    return (
        <SelectField
            label={label}
            options={preparedOptions}
            value={value}
            setValue={setValue}
            className={`${className}`}
            placeholder={placeholder}
            name={name}
            defaultValue={preparedDefaultValue}
            updateValues={updateValues}
            formatOptionLabel={formatOptionLabel}
            validateErrors={validateErrors}
            getError={getError}
            disabled={disabled}
            validationData={validationData}
            showLabel={showLabel}
            extraProps={{
                onFocus: getOptions,
                isSearchable: isSearchable,
                ...extraProps,
            }}
        />
    );
};

AsyncSelectField.defaultProps = {
    className: 'default-size',
    placeholder: '',
    optionsParser: (options) => options?.map((i) => ({
        value: i.id,
        label: i.name,
    })),
    defaultValueParser: (defaultValue) => ({
        value: defaultValue.id,
        label: defaultValue.name,
    }),
    getLoaderOption: (getLangText) => ({
        name: `${getLangText('loadingLabel')}...`,
        label: `${getLangText('loadingLabel')}...`,
        value: null,
        id: null
    }),
    isSearchable: false,
    showLabel: true,
    withLackOption: true,
    disabled: false,
}

export default AsyncSelectField;
