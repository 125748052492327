import React, {useState} from 'react';
import CreateShopLayout from "../../shared/CreateShopLayout";
import {
    ONBOARDING_ACCOUNT_TYPE_VIEW, ONBOARDING_CUSTOMER_TYPE_VIEW,
} from "../CreateShopFirstStep";
import CustomerType from "./CustomerType";
import AccountTypeOptions from "./AccountTypeOptions";
import useLang from "../../../../../src_shared/hooks/useLang";
import useLogout from "../../../shared/hooks/useLogout";

const AccountType = ({setActiveView}) => {
    const {getLangText} = useLang();
    const [internalView, setInternalView] = useState(ONBOARDING_ACCOUNT_TYPE_VIEW)
    const {logout} = useLogout();

    if (internalView === ONBOARDING_CUSTOMER_TYPE_VIEW) {
        return (
            <CustomerType
                setInternalView={setInternalView}
            />
        )
    }

    return (
        <div
            className={`bg-[#eff2f6] sm:py-0 sm:px-0 sm:min-h-auto min-h-full sm:h-[100vh] w-[100vw] flex sm:items-center sm:justify-center`}>
            <button
                onClick={logout}
                type="button"
                className="absolute bottom-2 left-2 text-desc text-xs underline z-10"
            >
                {getLangText('changeAccountLabel')}
            </button>
            <div
                className={`w-full sm:my-0 md:px-0 px-6 md:rounded-[12px] sm:shadow-default bg-white md:w-[800px] min-h-[450px] md:min-h-[600px] flex flex-col animate-tutorialSlideAndFadeIn`}>
                <div className={`sm:text-center animate-tutorialSlideAndFadeInDelay1 opacity-0 mt-10 sm:mt-20 sm:mb-12 mb-8`}>
                    <img width={100} className="sm:mx-auto mb-12 sm:mb-2" src="/assets/images/selmo-logo.svg" alt="selmo icon"/>
                    <h1 className={`font-bold text-[32px] mt-3`}
                        dangerouslySetInnerHTML={{__html: getLangText(internalView === ONBOARDING_ACCOUNT_TYPE_VIEW ? 'selectAccountTypeLabel' : '')}}/>
                </div>
                {internalView === ONBOARDING_ACCOUNT_TYPE_VIEW ?
                    <AccountTypeOptions
                        setActiveView={setActiveView}
                        setInternalView={setInternalView}
                    /> :
                    <CustomerType
                        setInternalView={setInternalView}
                    />
                }
            </div>
        </div>
    );
};

export default AccountType;
