import React, {useEffect, useState} from 'react';
import {batch, useDispatch, useSelector} from "react-redux";
import {galleryModalToggleVisibility, setImageGalleries} from "../galleryModal/GalleryModalActions";
import useLang from "../../../../../src_shared/hooks/useLang";
import moment from "moment";
import ServerImage from "../../../shared/components/serverImage/ServerImage";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";
import {MessengerDisplayType} from "../../chatList/ChatListReducer";

export const getDate = (date, locale) => {
    moment.locale(locale === 'us' ? 'en' : locale)
    if (!convertToUserTimeZone(date).isSame(convertToUserTimeZone(), 'year')) {
        return convertToUserTimeZone(date).format('DD MMM YYYY, HH:mm')
    }
    if (!convertToUserTimeZone(date).isSame(convertToUserTimeZone(), 'week')) {
        return convertToUserTimeZone(date).format('DD MMM, HH:mm')
    }
    if (!!convertToUserTimeZone(date).isSame(convertToUserTimeZone(), 'day')) {
        return convertToUserTimeZone(date).format('HH:mm')
    }

    return convertToUserTimeZone(date).format('ddd, HH:mm')
}

const Message = ({lastId, item, pageId, avatar, statusVisible, items, conversationId, index, displayType}) => {
    const [side, setSide] = useState('')
    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);
    const automaticMessage = displayType === MessengerDisplayType.ONLY_USER && item.order_link;
    useEffect(() => {
        if (pageId !== item.sender) {
            setSide('left');
        }
    }, [item, pageId])

    const isTheSameDate = (item) => {
        const indexOfItem = items.indexOf(items.find((i) => i.id === item.id))
        const previousItem = items[indexOfItem + 1]

        if (convertToUserTimeZone(item.date).isSame(convertToUserTimeZone(previousItem?.date), 'hours') && (previousItem?.sender === item.sender)) {
            return null;
        }
        return getDate(item.date, lang);
    }

    const getMessageClass = (item) => {
        let dateClass = ''
        if (!isTheSameDate(item)) {
            dateClass += 'without-date'
        }
        if (item.is_robot === '1') {
            dateClass += ' with-robot-icon'
        }
        if (item.is_svg === '1') {
            dateClass += ' svg-message'
        }
        const sideClass = pageId === item.sender ? 'right' : '';
        return `message ${sideClass} ${dateClass} ${index === 0 ? 'last-msg' : ''}`
    }

    if (!item.message && !item.images?.length && !item.audio && !item.video && !item.file && !item.image) {
        return null;
    }

    const urlify = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return '<a target="_blank" href="' + url + '">' + url + '</a>';
        })
    }

    const DateSeparator = ({date, id}) => {
        const indexOfItem = items.indexOf(items.find((i) => i.id === id))
        const previousItem = items[indexOfItem + 1]
        if (convertToUserTimeZone(date).isSame(convertToUserTimeZone(previousItem?.date), 'day')) {
            return null
        }
        return <div className="date-separator">{getDate(date, lang)}</div>
    }

    const onPhotoClick = (images, startIndex) => {
        batch(() => {
            dispatch(galleryModalToggleVisibility(true))
            dispatch(setImageGalleries(images, startIndex))
        })
    }

    const getOrderLink = () => {
        if (item.order_link.includes('inpost') || item.order_link.includes('furgonetka')) {
            return getLangText('followPackageLabel')
        }
        if (item.order_link.includes('dokument')) {
            return getLangText('seeDocumentsLabel')
        }
        return getLangText('goToOrderButton')
    }

    return (
        <>
            <div className={getMessageClass(item)}>
                {item.is_robot === '1' && (
                    <div className="robot-msg">
                        <img src="https://www.clipartmax.com/png/middle/295-2959545_bot-icon-bot-icon.png" alt=""/>
                    </div>
                )}
                {side === 'left'
                    && (
                        <div>
                            <figure className="avatar">
                                {avatar ?
                                    <ServerImage src={avatar} alt={avatar}/> :
                                    <img src="/assets/images/default/fb-default.jpeg" alt="deafult-image"/>
                                }
                            </figure>
                        </div>
                    )}
                <div className="content">
                    {(!item.is_sent || (statusVisible && lastId === Number(item.id))) &&
                        <div
                            className={`status-circle ${item.is_sent === 'filled' ? 'filled' : ''} ${!item.is_sent ? 'empty' : ''}`}/>
                    }
                    <div>
                        <div className="date">{getDate(item.date, lang)}</div>
                        {item.audio &&
                            <figure className="uploaded-photo audio-box">
                                <audio controls src={item.audio}/>
                            </figure>
                        }
                        {item.video &&
                            <figure className="uploaded-photo">
                                <video controls src={item.video}/>
                            </figure>
                        }
                        {item.images &&
                            <div className={`images-boxes ${item.images.length === 3 ? 'three-in-row' : ''}`}>
                                {item.images.map((i, key) => (
                                    !!i.file_url ?
                                        <div style={{width: 'auto'}}>
                                            <a
                                                href={i.file_url}
                                                download
                                                className="icon-button"
                                            >
                                                <i className="icon-orders"/>
                                            </a>
                                            <div
                                                className="file-label"
                                                style={{fontSize: '12px', opacity: 0.7}}>{i.name}</div>
                                        </div> :
                                        <div key={key}>
                                            <figure
                                                onClick={() => onPhotoClick(item.images, key)}
                                                className="uploaded-photo image">
                                                <img src={i?.image_data?.preview_url} alt={i.name}/>
                                            </figure>
                                        </div>
                                ))}
                            </div>
                        }
                        {item.image &&
                            <div className="images-boxes">
                                <div>
                                    <figure className="uploaded-photo image">
                                        <img src={item.image}/>
                                    </figure>
                                </div>
                            </div>
                        }
                        {item.file && (
                            <a
                                className="pdf-message-wrapper text-message-wrapper"
                                download
                                href={item.file}
                            >
                                <div className="text-message pdf-message">
                                    <div className="circle-icon">
                                        <i className="icon-summary"/>
                                    </div>
                                    <div className="name">
                                        {item.file_name}
                                        {item.file_size &&
                                            <div className="size">{item.file_size}</div>
                                        }
                                    </div>
                                </div>
                            </a>
                        )}
                        <div className="text-message-wrapper">
                            {item.message &&
                                <div className={`text-message`}>
                                    <div dangerouslySetInnerHTML={{__html: urlify(item.message)}}/>
                                    {item.order_link &&
                                        <a
                                            className="button-from-bot"
                                            href={item.order_link}
                                            target="_blank"
                                        >
                                            {getOrderLink()}
                                        </a>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <DateSeparator date={item.date} id={item.id}/>
        </>
    );
};

export default Message;
