import React, {useState} from 'react';
import {getDate} from "../../../../../messenger/chatBox/components/Message";
import {useDispatch, useSelector} from "react-redux";
import {liveDetailsUpdateCustomers} from "../../LiveDetailsActions";
import {createNewId, newCustomer} from "../../liveDetailsReducer";
import {
    PIANO_FASHION_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID,
    LALA_BOUTIQUE_SHOP_ID,
    MOI_BOUTIQUE_SHOP_ID,
    SHOP_BAZAR_SHOP_ID,
    SAMIPE_SHOP_ID,
    SPLENDOR_ROOM_SHOP_ID,
    MMODA_SHOP_ID,
    DRESS_WELL_SHOP_ID,
    IMPERIO_DAS_SHOP_ID,
    PINK_STORE_BY_LETICIA_SHOP_ID, MARIA_BONITA_SHOP_ID
} from "../../../../../../../src_shared/enums/TrustedShopsIds";
import ListActions from "../ListActions";
import {selmoUrl} from "../../../../../../../src_shared/api/api";
import {services} from "../../../../../../RestServices";
import {getGETParamsUrl} from "../../../../../../../src_shared/list/ListActions";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import api from "../../../../../../../services/axios/axios";
import ServerImage from "../../../../../shared/components/serverImage/ServerImage";

const Message = ({item, canAddClient = true}) => {
    const dispatch = useDispatch();
    const {userData} = useSelector((state) => state.session);
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);

    const [delayHandler, setDelayHandler] = useState(null)

    const {
        liveInfo,
        isInfoLoading,
        customers,
        product,
    } = useSelector((state) => state.lives.details)

    const isLiveFinished = !isInfoLoading && +liveInfo.finished;

    if (item.removed) {
        return null
    }

    const handleMouseEnter = () => {

        if (!!+item.mobile_app_purchase) return;

        if (item.shop_client_id) {
            return;
        }

        setDelayHandler(setTimeout(async () => {
            const params = {
                id: item.id
            }

            if (item.fromFacebookSocket) {
                params.comment_id = item.id;
            }

            try {
                const {data} = await api.get(`${selmoUrl}/${services.API_LIVE_SINGLE_CLIENT}${getGETParamsUrl(params)}`)
                dispatch(ListActions.updateLiveCommentsFromPusher({...item, ...data, fromFacebookSocket: false}))
            } catch (e) {
                console.warn('Cannot get client info')
            }
        }, 350))
    }

    const handleMouseLeave = () => {
        // setClientInfo(defaultClientInfo);
        clearTimeout(delayHandler);
    }


    const addCustomer = () => {
        const preparedCustomer = {
            client_id: item.facebook_id,
            name: item.user_name,
            photo: item.user_avatar,
            facebook_id: item.facebook_id,
            shop_client_id: item.shop_client_id,
            commentId: item.id,
            social_type: item.social_type,
        }

        const isNotEmptyVariants = (i) => !!product?.sizes?.length ? !i.variant_id : (!i.size && !i.color);

        const emptyRow = customers?.find((i) => !i.name && isNotEmptyVariants(i));

        if (+userData.id === TEST_ACCOUNT_SHOP_ID
            || +userData.id === PIANO_FASHION_SHOP_ID
            || +userData.id === LALA_BOUTIQUE_SHOP_ID
            || +userData.id === MMODA_SHOP_ID
            || +userData.id === MOI_BOUTIQUE_SHOP_ID
            || +userData.id === SHOP_BAZAR_SHOP_ID
            || +userData.id === SAMIPE_SHOP_ID
            || +userData.id === SPLENDOR_ROOM_SHOP_ID
            || +userData.id === DRESS_WELL_SHOP_ID
            || +userData.id === IMPERIO_DAS_SHOP_ID
            || +userData.id === PINK_STORE_BY_LETICIA_SHOP_ID
            || +userData.id === MARIA_BONITA_SHOP_ID
        ) {
            dispatch(ListActions.replyToComment(item.id))
        }

        if (emptyRow) {
            const preparedCustomers = customers.map((i) => i.id === emptyRow.id ? {
                ...i,
                ...preparedCustomer,
            } : i);
            dispatch(liveDetailsUpdateCustomers(preparedCustomers));
            return;
        }

        dispatch(liveDetailsUpdateCustomers([...customers,
            {
                ...newCustomer,
                ...preparedCustomer,
                id: createNewId(),
                tabIndex: customers[customers.length - 1]?.tabIndex + 1
            }
        ]));
    }

    const isCurrentlySelected = customers?.some((customer) => customer.commentId === item.id)

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={`message ${item.facebook_id ? 'ready-to-add' : ''} ${item.has_response === "1" ? 'responded' : ''} ${isCurrentlySelected ? 'selected' : ''}`}
        >
            <div>
                <figure className={`user-avatar`}>
                    {(item.user_avatar || item.mobile_user_avatar) ?
                        <ServerImage src={item.user_avatar || item.mobile_user_avatar}/> :
                        <img src="/assets/images/default/fb-default.jpeg"/>
                    }
                    {(userData.facebook_id && userData.instagram_id) &&
                        <>
                            {item.social_type === 'facebook' &&
                                <img className="msg-logo" src="/assets/images/svg/messenger-icon.svg"/>}
                            {item.social_type === 'instagram' &&
                                <img className="msg-logo" src="/assets/images/svg/instagram-icon.svg"/>}
                        </>
                    }
                    {(!isLiveFinished && item.facebook_id && canAddClient) &&
                        <button
                            className="add-customer"
                            onClick={addCustomer}
                        >
                            <i className="icon-plus"/>
                        </button>
                    }
                    {!!+item.mobile_app_purchase &&
                        <div
                            className={`absolute -right-1.5 -bottom-1.5 border-2 border-[#fff] rounded-full`}>
                            <img
                                className={`w-[14px] h-[14px] min-w-[14px]`}
                                src="/assets/images/svg/selmo-circle-blue.svg"
                                alt="selmo"
                            />
                        </div>
                    }
                </figure>
            </div>
            <div className="content">
                <div className="name">
                    {item.user_name || item.mobile_user_name || getLangText('noNameLabel')}
                </div>
                <div className="info-row">
                    {item.shop_client_id &&
                        <div className="number">
                            {getLangText('clientLabel')}: <strong>{item.shop_client_id}</strong>
                        </div>
                    }
                    {item.orders_count &&
                        <>
                            {!!+item.orders_count ?
                                <div className="number">
                                    {getLangText('ordersLabel')}: <strong>{item.orders_count}</strong>
                                </div> :
                                <div>
                                    <div className="status-bg green">{getLangText('newClientLabel')}</div>
                                </div>
                            }
                        </>
                    }
                    {!+item.conversation_id && !item.fromFacebookSocket && !+item.mobile_app_purchase &&
                        <div>
                            <div className="status-bg red">{getLangText('noConversationLabel')}</div>
                        </div>
                    }
                    {!!+item.mobile_app_purchase &&
                        <div>
                            <div className="status-bg blue">{getLangText('applicationLabel')}</div>
                        </div>
                    }
                </div>
                {item.reply_to &&
                    <div className="replied-message-container">
                        <div className="content">
                            <div className="name">
                                <i className="icon-respond"/>
                                {getLangText('toLabel')}:
                                <figure>
                                    {item.reply_to.user_avatar ?
                                        <ServerImage src={item.reply_to.user_avatar}/> :
                                        <img src="/assets/images/default/fb-default.jpeg"/>
                                    }
                                </figure>
                                <strong>{item.reply_to.user_name}</strong>
                            </div>
                            <div className="text">
                                {item.reply_to.message}
                            </div>
                        </div>
                    </div>
                }
                <div className="text">
                    {item.message}
                </div>
                <div className="date">
                    {getDate(item.date, lang)}
                </div>
            </div>
        </div>
    );
};

export default Message;
