import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import {
    SHOP_ORIGIN_EN,
    SHOP_ORIGIN_ES,
    SHOP_ORIGIN_FR,
    SHOP_ORIGIN_IT,
    SHOP_ORIGIN_PL,
    SHOP_ORIGIN_PT,
    SHOP_ORIGIN_SK
} from "../../../shared/enums/ShopOrigins";
import {useSelector} from "react-redux";

const SelmoAppAd = ({values}) => {
    const {getLangText} = useLang()
    const {lang} = useSelector((state) => state.i18n);

    const preparedLang = () => {
        if (lang === SHOP_ORIGIN_PL) return SHOP_ORIGIN_PL
        if (lang === SHOP_ORIGIN_IT) return SHOP_ORIGIN_IT
        if (lang === SHOP_ORIGIN_FR) return SHOP_ORIGIN_FR
        if (lang === SHOP_ORIGIN_SK) return SHOP_ORIGIN_SK
        if (lang === SHOP_ORIGIN_PT) return SHOP_ORIGIN_PT
        if (lang === SHOP_ORIGIN_ES) return SHOP_ORIGIN_ES
        return SHOP_ORIGIN_EN;
    }

    return (
        <a
            target="_blank"
            rel="noreferrer"
            href={`https://onelink.to/selmo?utm_source=selmo&utm_medium=cart&utm_campaign=cart_finish&utm_content=${values?.shopInfo?.id}`}
            className="relative mb-5 group block text-left"
        >
            <div className="absolute left-0 top-0 bottom-0 flex flex-col p-6 md:p-8 items-start">
                <div
                    dangerouslySetInnerHTML={{
                        __html: getLangText({
                            key: 'trackYourOrderInSelmoApp',
                            data: [values?.shopInfo?.name]
                        })
                    }}
                    className="text-[24px] leading-[34px] font-bold text-[#101827] whitespace-pre-wrap"/>
                <div
                    className="font-bold rounded-[10px] text-[16px] bg-[#2464EB] text-[#fff] inline-block px-[18px] py-2.5 mt-[20px] md:mt-auto hover:text-[#fff] transition-all hover:no-underline group-hover:bg-[#284ac7]"
                >
                    {getLangText('downloadApplicationLabel')}
                </div>
            </div>
            <img
                className="sm:rounded-[12px] left-0 top-0 w-full h-full object-cover md:block hidden rounded-[10px]"
                src={`/assets/images/multiLanguage/${preparedLang()}/mobileApp/cart-banner-desktop.webp`}
                alt=""
            />
            <img
                className="sm:rounded-[12px] left-0 top-0 w-full h-full object-cover sm:hidden rounded-[10px]"
                src={`/assets/images/multiLanguage/${preparedLang()}/mobileApp/cart-banner-rwd.webp`}
                alt=""
            />
        </a>
    );
};

export default SelmoAppAd;
